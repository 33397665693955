import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setStartTime } from "../../../store/actions/app";
import styles from "./redirectAsset.module.scss";

function RedirectAsset({ content }) {
  const { trigger, effect, start } = content;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function redirect() {
    const { page } = content;
    if (start) {
      dispatch(setStartTime());
    }

    navigate(page);
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.redirect}
      style={trigger.position}
      onClick={redirect}
    >
      <img
        src={trigger.image}
        alt=""
        style={trigger.size}
        className={effect === "pulse" ? styles.pulse : ""}
      />
    </div>
  );
}

export default RedirectAsset;
