export const ADD_TO_INVENTORY = "ADD_TO_INVENTORY";
export const SET_OBJECT_VALUE = "SET_OBJECT_VALUE";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const SET_ROOM_TIME = "SET_ROOM_TIME";
export const SET_MUTE_STATUS = "SET_MUTE_STATUS";
export const SET_UNLOCKED = "SET_UNLOCKED";
export const REMOVE_UNLOCKED = "REMOVE_UNLOCKED";
export const RESET_STORE = "RESET_STORE";
export const SET_DEBUG_STATUS = "SET_DEBUG_STATUS";
export const SET_PERSONAL_GOAL = "SET_PERSONAL_GOAL";
export const SET_PROGRESS = "SET_PROGRESS";
export const ADD_TO_HINTS = "ADD_TO_HINTS";
export const ADD_TO_PUZZLES_SOLVED = "ADD_TO_PUZZLES_SOLVED";

export const addItemToInventory = (payload) => ({
  type: ADD_TO_INVENTORY,
  payload,
});

export const setObjectValue = (payload) => ({
  type: SET_OBJECT_VALUE,
  payload,
});

export const setMuteStatus = (payload) => ({
  type: SET_MUTE_STATUS,
  payload,
});

export const setStartTime = (payload) => ({
  type: SET_START_TIME,
  payload,
});

export const setEndTime = (payload) => ({
  type: SET_END_TIME,
  payload,
});

export const setRoomTime = (payload) => ({
  type: SET_ROOM_TIME,
  payload,
});

export const setUnlocked = (payload) => ({
  type: SET_UNLOCKED,
  payload,
});

export const removeUnlocked = (payload) => ({
  type: REMOVE_UNLOCKED,
  payload,
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const setPersonalGoal = (payload) => ({
  type: SET_PERSONAL_GOAL,
  payload,
});

export const setProgress = (payload) => ({
  type: SET_PROGRESS,
  payload,
});

export const setDebugStatus = (payload) => ({
  type: SET_DEBUG_STATUS,
  payload,
});

export const setPuzzleSolved = (payload) => ({
  type: ADD_TO_PUZZLES_SOLVED,
  payload,
});

export const setHintEvent = (payload) => ({
  type: ADD_TO_HINTS,
  payload,
});
