import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LottieArrows from "../../../assets/lottie/arrowrow.json";
// import LottieUnlocked from "../../../assets/lottie/unlocked.json";
import PuzzleSolvedAsset from "../../../assets/shuffle/maze.png";
import { INVENTORY_PUZZLE_1 } from "../../../content/inventory/inventoryItems";
import {
  setPersonalGoal,
  setPuzzleSolved,
  setUnlocked,
} from "../../../store/actions/app";
import { getDebugStatus, getUnlocked } from "../../../store/reducers";
import SubmitButton from "../../ui/submitButton/submitButton";
import UnlockedAsset from "../../ui/unlockedAsset/unlockedAsset";
import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./slidepuzzle.module.scss";

const PUZZLE_SIZE = 3;
const NUM_TILES = PUZZLE_SIZE ** 2;

function Tile({ id, blank, handleClick, image }) {
  const tileStyles = cn(styles.tile, {
    [styles.blank]: blank,
    [styles[`tile${id}`]]: true,
  });
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={tileStyles}
      style={{ backgroundImage: `url(${image})` }}
      onClick={handleClick}
    />
  );
}

function SlidePuzzle({ content }) {
  const unlockedArray = useSelector(getUnlocked);
  const isSolved = unlockedArray.includes(INVENTORY_PUZZLE_1);

  const [debugClick, setDebugClick] = useState(0);
  const [tiles, setTiles] = useState([]);
  const [solved, setSolved] = useState(false);
  const [shuffled, setShuffled] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const [currentHintIndex, setCurrentHintIndex] = useState(isSolved ? 1 : 0);
  const SCREEN_WIDTH = 560;
  const [selectedGoal, setSelectedGoal] = useState();
  const ownGoal = useRef();

  const ACTIVITIES = [
    { id: 1, title: "Beter worden in sporten" },
    { id: 2, title: "Diploma halen" },
    { id: 3, title: "Gezond zijn" },
    { id: 4, title: "Leuk baantje" },
    { id: 5, title: "Goede band met ouders" },
    { id: 6, title: "Fit voelen" },
    { id: 7, title: "Lekker in je vel zitten" },
    { id: 8, title: "Healthy lifestyle hebben" },
    { id: 9, title: "Gym vaker pakken" },
  ];

  useEffect(() => {
    const solvedNow = unlockedArray.includes(INVENTORY_PUZZLE_1);
    if (solvedNow) {
      setSuccess(true);
    }
  }, [unlockedArray]);

  const debugMode = useSelector(getDebugStatus);

  const puzzleContainerStyles = cn(styles.puzzleContainer, {
    [styles.success]: success,
    [styles.notShuffled]: !shuffled,
  });

  function setSelected(index1, index2, array) {
    const temp = array[index1];
    // eslint-disable-next-line no-param-reassign
    array[index1] = array[index2];
    // eslint-disable-next-line no-param-reassign
    array[index2] = temp;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function swap(highlightedTileIndex, newTileIndex, tileArray) {
    if (newTileIndex < 0 || newTileIndex > NUM_TILES - 1) {
      return;
    }
    // Check if we are trying to swap right
    if (newTileIndex === highlightedTileIndex + 1) {
      if ((newTileIndex + 1) % PUZZLE_SIZE !== 1) {
        setSelected(highlightedTileIndex, newTileIndex, tileArray);
      }
      // Check if we are trying to swap left
    } else if (newTileIndex === highlightedTileIndex - 1) {
      if ((newTileIndex + 1) % PUZZLE_SIZE !== 0) {
        setSelected(highlightedTileIndex, newTileIndex, tileArray);
      }
      // Check if we are trying to swap up
    } else if (newTileIndex === highlightedTileIndex + PUZZLE_SIZE) {
      setSelected(highlightedTileIndex, newTileIndex, tileArray);
      // Check if we are trying to swap down
    } else if (newTileIndex === highlightedTileIndex - PUZZLE_SIZE) {
      setSelected(highlightedTileIndex, newTileIndex, tileArray);
    }
  }

  function handleTileClick(newTileIndex) {
    const tilesCopy = [...tiles];
    const isHighlightedTileId = (element) => element.id === NUM_TILES;
    const highlightedTileIndex = tilesCopy.findIndex(isHighlightedTileId);
    swap(highlightedTileIndex, newTileIndex, tilesCopy);
    setTiles(tilesCopy);
  }

  function handleSuccess() {
    setSuccess(true);
    dispatch(setUnlocked(INVENTORY_PUZZLE_1));
    dispatch(setPuzzleSolved(INVENTORY_PUZZLE_1));
  }

  useEffect(() => {
    function shuffle() {
      const minShuffles = 5;
      const totalShuffles =
        minShuffles + Math.floor(Math.random() * (200 - 100) + 100);
      const tilesToShuffle = [...tiles];

      for (let i = minShuffles; i <= totalShuffles; i++) {
        setTimeout(() => {
          const isHighlightedTileId = (element) => element.id === NUM_TILES;
          const highlightedTileIndex =
            tilesToShuffle.findIndex(isHighlightedTileId);
          let newTileIndex;

          const x = Math.floor(Math.random() * 4);
          if (x === 0) {
            newTileIndex = highlightedTileIndex + 1;
          } else if (x === 1) {
            newTileIndex = highlightedTileIndex - 1;
          } else if (x === 2) {
            newTileIndex = highlightedTileIndex + PUZZLE_SIZE;
          } else if (x === 3) {
            newTileIndex = highlightedTileIndex - PUZZLE_SIZE;
          }

          swap(highlightedTileIndex, newTileIndex, tilesToShuffle);

          if (i === totalShuffles) {
            setTiles(tilesToShuffle);
            setShuffled(true);
          }
        }, i * 10);
      }
    }

    if (tiles.length > 0 && !shuffled) {
      shuffle();
    }
  }, [tiles, shuffled, debugMode, swap]);

  useEffect(() => {
    const items = [];
    for (let b = 1; b <= NUM_TILES; b++) {
      items.push({ id: b, blank: b === NUM_TILES });
    }
    setTiles(items);
  }, []);

  useEffect(() => {
    function checkHasWon() {
      let tileShouldHaveIndex;
      for (let b = 0; b < NUM_TILES; b++) {
        tileShouldHaveIndex = tiles[b].id - 1;
        if (parseInt(b, 10) !== parseInt(tileShouldHaveIndex, 10)) {
          return false;
        }
      }
      return true;
    }

    if (shuffled) {
      if (checkHasWon() || debugMode) {
        setSolved(true);
      }
    }
  }, [tiles, shuffled, debugMode]);

  function handleSelectActivities() {
    dispatch(setPersonalGoal(selectedGoal.title));
    setCurrentHintIndex(1);
  }

  function typeOwnGoal(typeValue) {
    if (typeValue) {
      setSelectedGoal({ id: 10, title: typeValue });
    } else {
      setSelectedGoal({});
    }
  }
  function setPreSelectedGoal(activity) {
    ownGoal.current.value = "";
    setSelectedGoal(activity);
  }

  function onPressDebugButton() {
    if (debugClick > 5) {
      setDebugClick(0);
      setSolved(true);
    } else {
      setDebugClick(debugClick + 1);
    }
  }

  return (
    <div className={styles.slidePuzzle}>
      <div
        className={styles.shuffleContainer}
        style={{
          transform: `translateX(-${SCREEN_WIDTH * currentHintIndex}px)`,
        }}
      >
        <div className={styles.shufflePage}>
          <div className={styles.goalListContainer}>
            <h1>Wat is jouw doel in het leven?</h1>
            {ACTIVITIES.map((activity, index) => {
              const buttonStyles = cn(styles.button, {
                [styles.selected]: selectedGoal?.id === activity.id,
              });

              return (
                <button
                  key={index}
                  type="button"
                  className={buttonStyles}
                  onClick={() => setPreSelectedGoal(activity)}
                >
                  {activity.title}
                </button>
              );
            })}
            <textarea
              ref={ownGoal}
              placeholder="anders, namelijk..."
              onChange={(event) => {
                typeOwnGoal(event.target.value);
              }}
            />
            <SubmitButton
              callback={handleSelectActivities}
              disabled={!selectedGoal?.id}
            />
          </div>
        </div>
        <div className={styles.shufflePage}>
          <div className={puzzleContainerStyles} style={content.position}>
            {!success && (
              <LottieAsset
                clickHandler={onPressDebugButton}
                content={{
                  animation: LottieArrows,
                  position: { top: -50, left: 0, width: 222 },
                }}
              />
            )}
            {!success &&
              tiles.map((tile, index) => (
                <Tile
                  key={index}
                  id={tile.id}
                  blank={tile.blank}
                  handleClick={() => handleTileClick(index)}
                  image={content.image}
                />
              ))}
            {success && (
              <>
                <img src={PuzzleSolvedAsset} alt="" />
                <div className={styles.pulsingButton} />
              </>
            )}
            {solved && !success && (
              // <LottieAsset
              //   loop={false}
              //   handleComplete={handleSuccess}
              //   content={{
              //     animation: LottieUnlocked,
              //     position: { top: -200, left: 0, width: 560 },
              //   }}
              // />
              <UnlockedAsset
                handleComplete={handleSuccess}
                content={{
                  position: { top: 250, left: 0, width: 540 },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlidePuzzle;
