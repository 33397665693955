import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";

import { secondsToClockTime } from "../../../utilities/timeHelpers";
import styles from "./countdownClock.module.scss";

function CountdownClock({ startTime, totalTime, finalSeconds, type }) {
  const [timeString, setTimeString] = useState("00:00");
  const clockClasses = classNames(styles.digitalClock, {
    [styles.phoneClock]: type === "phone",
    [styles.blue]: type === "blue",
  });

  useEffect(() => {
    let clockInterval;

    if (!finalSeconds) {
      if (startTime && totalTime) {
        const secondsSinceStart = moment().unix() - startTime;
        const secondsLeft = totalTime - secondsSinceStart;
        setTimeString(secondsToClockTime(secondsLeft));

        clockInterval = setInterval(() => {
          const secondsSinceStartStamp = moment().unix() - startTime;
          const secondsLeftStamp = totalTime - secondsSinceStartStamp;
          setTimeString(secondsToClockTime(secondsLeftStamp));
        }, 1000);
      }
    } else {
      setTimeString(secondsToClockTime(finalSeconds));
    }

    return () => {
      if (clockInterval) clearInterval(clockInterval);
    };
  }, [startTime, totalTime, setTimeString, finalSeconds]);

  return <div className={clockClasses}>{timeString}</div>;
}

export default CountdownClock;
