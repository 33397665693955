// import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import LottieArrows from "../../../assets/lottie/arrowrow.json";
import LottieSquares from "../../../assets/lottie/cubes3.json";
import SquareAsset from "../../../assets/room4/square.png";
import TriangleAsset from "../../../assets/room4/triangle.png";
import GreenLightAsset from "../../../assets/roomLights/green_light.png";
import RedLightAsset from "../../../assets/roomLights/red_light.png";
import ArrowAsset from "../../../assets/ui/arrow-right.png";
import AssetLeftArrow from "../../../assets/windowsMinigame/arrow_left.png";
import { setUnlocked } from "../../../store/actions/app";
import soundEffects, {
  SOUND_EFFECT_SUCCESS,
} from "../../../utilities/soundEffects";
import ErrorOverlay from "../../ui/errorOverlay/errorOverlay";
import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./roomLights.module.scss";

function RoomLightsForm({ type, solution, onClose, puzzleKey }) {
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const timer = useRef();

  useEffect(() => {
    if (error) {
      timer.current = setTimeout(() => {
        setError(false);
        setInputValue("");
      }, 5000);
    }
  }, [error]);

  function evaluateCode() {
    if (solution.toLowerCase().trim() === inputValue.toLowerCase().trim()) {
      setInputValue("");
      soundEffects.playSoundEffect(SOUND_EFFECT_SUCCESS);
      dispatch(setUnlocked(puzzleKey));
      onClose();
    } else {
      setError(true);
    }
  }

  return (
    <>
      <LottieAsset
        content={{
          animation: LottieArrows,
          position: { top: 40, left: 280, width: 222 },
        }}
      />
      <LottieAsset
        content={{
          animation: LottieSquares,
          position: { top: 35, left: 50, width: 135 },
        }}
      />
      <div className={styles.panel}>
        <div className={styles.code}>
          <p>Code</p>
          {type === "GREEN" && (
            <>
              <img src={SquareAsset} alt="symbol" />
              <img src={GreenLightAsset} alt="light" />
            </>
          )}
          {type === "RED" && (
            <>
              <img src={TriangleAsset} alt="symbol" />
              <img src={RedLightAsset} alt="light" />
            </>
          )}
        </div>
        <input
          value={inputValue}
          type="text"
          onChange={(event) => setInputValue(event.target.value)}
        />
        <button
          className={styles.submit}
          type="submit"
          disabled={inputValue.length === 0}
          onClick={evaluateCode}
        >
          <img alt="submit" src={ArrowAsset} />
        </button>
      </div>
      <button type="button" className={styles.back} onClick={onClose}>
        <img src={AssetLeftArrow} alt="" />
      </button>
      {error && <ErrorOverlay />}
    </>
  );
}

export default RoomLightsForm;
