export const SET_PHONE_EVENT = "SET_PHONE_EVENT";
export const SET_PHONE_SEEN_EVENT = "SET_PHONE_SEEN_EVENT";
export const CLEAR_PHONE_EVENTS = "CLEAR_PHONE_EVENTS";
export const SET_INVENTORY_ANIMATION_EVENT = "SET_INVENTORY_ANIMATION_EVENT";
export const SET_PROGRESS_ANIMATION_EVENT = "SET_PROGRESS_ANIMATION_EVENT";
export const SET_BATTERY_REMINDER_SEEN = "SET_BATTERY_REMINDER_SEEN";

export const setPhoneEvent = (payload) => ({
  type: SET_PHONE_EVENT,
  payload,
});

export const setPhoneEventSeen = (payload) => ({
  type: SET_PHONE_SEEN_EVENT,
  payload,
});

export const clearPhoneEvents = () => ({
  type: CLEAR_PHONE_EVENTS,
});

export const setInventoryAnimationEvent = (payload) => ({
  type: SET_INVENTORY_ANIMATION_EVENT,
  payload,
});

export const setProgressAnimationEvent = (payload) => ({
  type: SET_PROGRESS_ANIMATION_EVENT,
  payload,
});

export const setBatteryReminderSeen = (payload) => ({
  type: SET_BATTERY_REMINDER_SEEN,
  payload,
});
