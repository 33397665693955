import cn from "classnames";
import { useSelector } from "react-redux";

import { getUnlocked } from "../../../store/reducers";
import styles from "./videoAsset.module.scss";

function VideoAsset({ content, onVideoEnded }) {
  const {
    hideWhen,
    showWhen,
    position,
    video,
    size,
    staticPosition,
    closeOnEnd,
  } = content;

  const unlockedArray = useSelector(getUnlocked);
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  const shouldBeShown = unlockedArray.includes(showWhen);

  const videoAssetStyles = cn(styles.videoAsset, {
    [styles.static]: staticPosition,
  });

  if (shouldBeHidden || (showWhen && !shouldBeShown)) {
    return null;
  }

  return (
    <div className={videoAssetStyles} style={position}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={video}
        alt=""
        style={size}
        controls={false}
        playsInline
        autoPlay
        onEnded={closeOnEnd && onVideoEnded}
      />
    </div>
  );
}

export default VideoAsset;
