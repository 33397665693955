import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPuzzleSolved, setUnlocked } from "../../store/actions/app";
import { getUnlocked } from "../../store/reducers";
import soundEffects, { SOUND_EFFECT_WRONG } from "../../utilities/soundEffects";
// eslint-disable-next-line import/no-cycle
import Interactives from "../interactives/interactives";
import Menu from "../ui/menu/menu";
import styles from "./scene.module.scss";

function Scene({ content, goToNextRoom }) {
  const { hideWhen, showWhen, trigger, locked } = content;
  const unlockedArray = useSelector(getUnlocked);

  const sceneEl = useRef(null);
  const dispatch = useDispatch();
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  const shouldBeShown = unlockedArray.includes(showWhen);

  const [showScene, setShowScene] = useState();
  const [sceneIndex, setSceneIndex] = useState(0);
  const [detail, setDetail] = useState(null);
  const [objects, setObjects] = useState(null);

  useEffect(() => {
    if (content.states) {
      setDetail(content.states[sceneIndex].detail);
      setObjects(content.states[sceneIndex].objects);
    } else {
      setDetail(content.detail);
      setObjects(content.objects);
    }
  }, [content, sceneIndex]);

  if (shouldBeHidden || (showWhen && !shouldBeShown)) {
    return null;
  }

  function onCodeUnlocked(success) {
    dispatch(setPuzzleSolved(success.puzzle));

    switch (success.type) {
      case "next-stage":
        setSceneIndex(1);
        break;
      case "redirect":
        goToNextRoom(success.page, success.required);
        break;
      case "unlock":
        dispatch(setUnlocked(success.puzzle));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`NO ACTION FOR TYPE ${success.type}`);
        break;
    }
  }

  function showSceneIfNotLocked() {
    const isUnlocked = unlockedArray.includes(locked);
    if (!locked || isUnlocked) {
      setShowScene(true);
    } else {
      soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
    }
  }

  const clickAreaStyles = cn(styles.clickArea, {
    [styles.pulsing]: trigger?.pulsing,
  });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={clickAreaStyles}
        style={trigger.position}
        onClick={showSceneIfNotLocked}
      >
        <img src={trigger.image} alt="" style={trigger.size} />
      </div>

      {showScene && detail && (
        <div ref={sceneEl} className={styles.scene}>
          <img src={detail.image} alt="" style={detail.size} />
          <Interactives
            objects={objects}
            onCodeUnlocked={onCodeUnlocked}
            onVideoEnded={() => setShowScene(false)}
          />
          <div className={styles.sceneMenu}>
            <Menu handleClose={() => setShowScene(false)} />
          </div>
        </div>
      )}
    </>
  );
}

export default Scene;
