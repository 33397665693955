import { useEffect } from "react";

import ImageRoom from "../../components/rooms/imageRoom/imageRoom";
import Menu from "../../components/ui/menu/menu";
import content from "../../content/rooms/room1";
import soundEffects from "../../utilities/soundEffects";
import styles from "./room.module.scss";

function Room1() {
  useEffect(() => {
    soundEffects.playBackgroundSound();
  });

  return (
    <>
      <ImageRoom content={content} />;
      <div className={styles.menuPlacer}>
        <Menu />
      </div>
    </>
  );
}

export default Room1;
