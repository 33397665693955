import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import ControllerErrorAsset from "../../../assets/controller/A_missing.png";
import AbuttonAsset from "../../../assets/controller/gate_cont_A.png";
import DownAsset from "../../../assets/controller/gate_cont_arrow_Down.png";
import LeftAsset from "../../../assets/controller/gate_cont_arrow_L.png";
import RightAsset from "../../../assets/controller/gate_cont_arrow_R.png";
import UpAsset from "../../../assets/controller/gate_cont_arrow_Up.png";
import BbuttonAsset from "../../../assets/controller/gate_cont_B.png";
import XbuttonAsset from "../../../assets/controller/gate_cont_X.png";
import YbuttonAsset from "../../../assets/controller/gate_cont_Y.png";
import InputAsset from "../../../assets/mazeMap/input_goal.png";
import {
  INVENTORY_PUZZLE_2_BUTTON_A,
  INVENTORY_PUZZLE_2_BUTTON_B,
  INVENTORY_PUZZLE_2_BUTTON_X,
  INVENTORY_PUZZLE_2_BUTTON_Y,
} from "../../../content/inventory/inventoryItems";
import {
  getDebugStatus,
  getPersonalGoal,
  getUnlocked,
} from "../../../store/reducers";
import soundEffects, {
  SOUND_EFFECT_WRONG,
} from "../../../utilities/soundEffects";
import ErrorOverlay from "../../ui/errorOverlay/errorOverlay";
import styles from "./gate.module.scss";

function Gate({ content, onCodeUnlocked }) {
  const unlockedArray = useSelector(getUnlocked);
  const [enteredCode, setEnteredCode] = useState([]);
  const timer = useRef();
  const { code, video } = content;
  const [error, setError] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const personalGoal = useSelector(getPersonalGoal);

  const isDebugStatus = useSelector(getDebugStatus);

  const controls = [
    { id: "UP", asset: UpAsset },
    { id: "RIGHT", asset: RightAsset },
    { id: "DOWN", asset: DownAsset },
    { id: "LEFT", asset: LeftAsset },
  ];

  const buttons = [
    {
      id: INVENTORY_PUZZLE_2_BUTTON_A,
      asset: AbuttonAsset,
      className: styles.buttonA,
    },
    {
      id: INVENTORY_PUZZLE_2_BUTTON_Y,
      asset: YbuttonAsset,
      className: styles.buttonY,
    },
    {
      id: INVENTORY_PUZZLE_2_BUTTON_B,
      asset: BbuttonAsset,
      className: styles.buttonB,
    },
    {
      id: INVENTORY_PUZZLE_2_BUTTON_X,
      asset: XbuttonAsset,
      className: styles.buttonX,
    },
  ];

  function clearTimer() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }

  function showEndvideo() {
    setShowVideo(true);
  }

  function onVideoEnded() {
    onCodeUnlocked(content.success);
  }

  function evaluateCode(newCode) {
    // Check part of code that is entered
    const codeToCheck = code.slice(0, newCode.length);

    if (JSON.stringify(codeToCheck) !== JSON.stringify(newCode)) {
      setError(true);
      clearTimer();
      soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);

      timer.current = setTimeout(() => {
        setEnteredCode([]);
        setError(false);
      }, 5000);
    } else if (newCode.length === code.length) {
      showEndvideo();
    }
  }

  function inputClick(id) {
    const newCode = [...enteredCode];
    newCode.push(id);
    setEnteredCode(newCode);

    evaluateCode(newCode);
  }

  function onErrorComplete() {
    setEnteredCode([]);
    setError(false);
  }

  return (
    <div className={styles.gameController}>
      <div className={styles.controls}>
        {controls.map((control, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <img
            key={index}
            alt=""
            src={control.asset}
            onClick={() => inputClick(control.id)}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        {buttons.map((button, index) => {
          const isUnlocked = unlockedArray.includes(button.id);
          if (isUnlocked || isDebugStatus) {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <img
                key={index}
                alt=""
                src={button.asset}
                className={button.className}
                onClick={() => inputClick(button.id)}
              />
            );
          }
          return null;
        })}
      </div>
      {error && (
        <>
          <img
            src={ControllerErrorAsset}
            alt=""
            className={styles.buttonError}
          />
          <ErrorOverlay onComplete={onErrorComplete} />
        </>
      )}
      {showVideo && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          src={video}
          alt=""
          className={styles.successVideo}
          controls={false}
          playsInline
          autoPlay
          onEnded={onVideoEnded}
        />
      )}
      {showVideo && !unlockedArray.includes(INVENTORY_PUZZLE_2_BUTTON_Y) && (
        <div className={styles.goalInputText}>
          <img alt="" src={InputAsset} />
          <textarea
            className={styles.textareaField}
            rows={2}
            defaultValue={personalGoal}
            readOnly
          />
        </div>
      )}
    </div>
  );
}

export default Gate;
