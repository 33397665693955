import styles from "./menu.module.scss";

function MenuButton({ handleClick, asset, alt, position, disabled }) {
  const [top, left] = position;

  function onClick() {
    if (!disabled) {
      handleClick();
    }
  }

  return (
    <button
      type="button"
      className={styles.menuButton}
      style={{ top: `${top}px`, left: `${left}px` }}
      onClick={onClick}
    >
      <img src={asset} alt={alt} />
    </button>
  );
}

export default MenuButton;
