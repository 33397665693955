import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getDebugStatus } from "../../../store/reducers";
import { debugValue } from "../../../utilities/debugValue";
import soundEffects, {
  SOUND_EFFECT_SUCCESS,
  SOUND_EFFECT_WRONG,
} from "../../../utilities/soundEffects";
import styles from "./textInput.module.scss";

function TextInput({ content, onCodeUnlocked, close }) {
  const debugMode = useSelector(getDebugStatus);
  const [codeValue, setCodeValue] = useState(
    debugValue([], content.code, debugMode)
  );
  const [inputCorrect, setInputCorrect] = useState([]);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();

  const { detail } = content;

  const modifyObjectToUniversalString = (string) =>
    JSON.stringify(string).toLowerCase().replace(/\s+/g, "");

  useEffect(() => () => timer.current && clearTimeout(timer.current), []);

  function setInputValue(event, index) {
    if (error) {
      soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
      return;
    }

    const { value } = event.target;
    const newValue = [...codeValue];

    newValue[index] = value;
    setCodeValue(newValue);
  }

  function onError() {
    setError(true);
    soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
    timer.current = setTimeout(() => {
      setError(false);
    }, 5000);
  }

  function onSuccess() {
    setSuccess(true);
    soundEffects.playSoundEffect(SOUND_EFFECT_SUCCESS);
    timer.current = setTimeout(() => {
      onCodeUnlocked(content.success);
      setCodeValue([]);
      close();
    }, 1000);
  }

  function getInputClasses(index) {
    if (!success && !error) return styles.textInput;
    if (success) return classNames(styles.textInput, styles.success);

    return classNames(styles.textInput, {
      [styles.error]: !inputCorrect[index],
      [styles.success]: inputCorrect[index],
    });
  }

  function checkValue() {
    const result = [];
    for (let i = 0; i < content.code.length; i++) {
      result[i] =
        codeValue[i] &&
        modifyObjectToUniversalString(codeValue[i]) ===
          modifyObjectToUniversalString(content.code[i]);
    }

    if (
      result.filter((field) => field === true).length === content.code.length
    ) {
      onSuccess();
    } else {
      setInputCorrect(result);
      onError();
    }
  }

  return (
    <>
      <div className={styles.container} style={detail.inputPosition}>
        {content.code.map((input, index) => (
          <input
            key={index}
            type="text"
            value={codeValue[index]}
            name={index}
            className={getInputClasses(index)}
            onChange={(event) => setInputValue(event, index)}
          />
        ))}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.codeSubmit}
        style={detail.submit.position}
        onClick={checkValue}
      >
        <img src={detail.submit.asset} alt="" />
      </div>
    </>
  );
}

export default TextInput;
