import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addItemToInventory,
  setPuzzleSolved,
  setUnlocked,
} from "../../../store/actions/app";
import { getInventory } from "../../../store/reducers";
import soundEffects, {
  SOUND_EFFECT_PICKUP,
} from "../../../utilities/soundEffects";
import styles from "../../scene/scene.module.scss";

function InventoryItem({ content }) {
  const dispatch = useDispatch();
  const timer = useRef();
  const inventory = useSelector(getInventory);
  const [hideItem, setHideItem] = useState(false);
  const { trigger, id } = content;

  useEffect(() => () => {
    if (timer.current) clearTimeout(timer.current);
  });

  function collectItem() {
    soundEffects.playSoundEffect(SOUND_EFFECT_PICKUP);
    if (!timer.current) {
      timer.current = setTimeout(() => {
        dispatch(addItemToInventory(id));
        dispatch(setUnlocked(id));
        dispatch(setPuzzleSolved(id));
      }, 800);
    }
  }

  useEffect(() => {
    if (inventory.includes(id)) {
      setHideItem(true);
    }
  }, [inventory, id]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.clickArea}
      style={trigger.position}
      onClick={collectItem}
    >
      {!hideItem && (
        <img
          src={trigger.image}
          alt=""
          style={trigger.size}
          className={styles.object}
        />
      )}
    </div>
  );
}

export default InventoryItem;
