import classNames from "classnames";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "react-router";
import { useLocation } from "react-router-dom";

import hints from "../../content/hints.json";
import { setHintEvent } from "../../store/actions/app";
import {
  getHints,
  getPuzzlesSolved,
  getRoomTime,
  getShowHint,
  getStartTime,
} from "../../store/reducers";
import HintPopup from "../ui/hintPopup/hintPopup";
import styles from "./hint.module.scss";

function Hint() {
  const seenHints = useSelector(getHints);
  const solvedPuzzled = useSelector(getPuzzlesSolved);

  const hint = useSelector(getShowHint);
  const timer = useRef();
  const [showHint, setShowHint] = useState(false);
  const [currentHint, setCurrentHint] = useState(undefined);
  const location = useLocation();
  const roomTime = useSelector(getRoomTime);
  const gameTime = useSelector(getStartTime);
  const dispatch = useDispatch();
  const history = useNavigation();

  useEffect(() => {
    if (gameTime && roomTime) {
      if (timer.current) clearInterval(timer.current);
      const hintsByRoom = hints
        .filter((hintContent) => hintContent.room === location.pathname)
        .filter((hintContent) => hintContent.time);
      const hintsByGame = hints
        .filter((hintContent) => hintContent.room === "all")
        .filter((hintContent) => hintContent.time);

      const notSeenOrSolvedHintsByRoom = hintsByRoom
        .filter((hintItem) => !seenHints?.includes(hintItem.id))
        .filter((hintItem) => !solvedPuzzled?.includes(hintItem.puzzle));
      const notSeenOrSolvedHintsByGame = hintsByGame
        .filter((hintItem) => !seenHints?.includes(hintItem.id))
        .filter((hintItem) => !solvedPuzzled?.includes(hintItem.puzzle));
      let roomHintToDispatch;
      let gameHintToDispatch;
      let hintToDispatch;
      let timeToCheck;
      if (notSeenOrSolvedHintsByRoom && notSeenOrSolvedHintsByRoom.length > 0) {
        notSeenOrSolvedHintsByRoom.sort((a, b) => a.time - b.time);
        // eslint-disable-next-line prefer-destructuring
        roomHintToDispatch = notSeenOrSolvedHintsByRoom[0];
      }

      if (notSeenOrSolvedHintsByGame && notSeenOrSolvedHintsByGame.length > 0) {
        notSeenOrSolvedHintsByGame.sort((a, b) => a.time - b.time);
        // eslint-disable-next-line prefer-destructuring
        gameHintToDispatch = notSeenOrSolvedHintsByGame[0];
      }
      // If we have hints in both categories
      if (roomHintToDispatch && gameHintToDispatch) {
        const roomHintTimeToFire =
          roomHintToDispatch.time - (moment().unix() - roomTime);
        const gameHintTimeToFire =
          gameHintToDispatch.time - (moment().unix() - gameTime);

        // What hint comes first
        if (roomHintTimeToFire < gameHintTimeToFire) {
          hintToDispatch = roomHintToDispatch;
          timeToCheck = roomTime;
        } else {
          hintToDispatch = gameHintToDispatch;
          timeToCheck = gameTime;
        }
        // Else pick one of the two
      } else {
        if (roomHintToDispatch) {
          hintToDispatch = roomHintToDispatch;
          timeToCheck = roomTime;
        }
        if (gameHintToDispatch) {
          hintToDispatch = gameHintToDispatch;
          timeToCheck = gameTime;
        }
      }

      if (hintToDispatch) {
        timer.current = setInterval(() => {
          const timePlayed = moment().unix() - timeToCheck;
          // console.log(timePlayed, hintToDispatch);
          if (hintToDispatch.time && hintToDispatch.time < timePlayed) {
            if (hintToDispatch.id === "BLACK_SCREEN") {
              // Quick and dirty
              clearInterval(timer.current);
            } else {
              dispatch(setHintEvent(hintToDispatch.id));
              clearInterval(timer.current);
            }
          }
        }, 1000);
      }
    }
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [
    location,
    seenHints,
    dispatch,
    roomTime,
    solvedPuzzled,
    gameTime,
    history,
  ]);

  useEffect(() => {
    if (hint) {
      const hintContent = hints.find(
        (hintContentItem) => hintContentItem.id === hint
      );
      setCurrentHint(hintContent);
      setShowHint(true);
    }
  }, [hint]);

  function closeHint() {
    dispatch(setHintEvent(null));
    setShowHint(false);
  }

  const hintContainerClasses = classNames(styles.hintContainer, {
    [styles.show]: showHint && currentHint,
  });

  return (
    <div className={hintContainerClasses}>
      {showHint && currentHint && (
        <HintPopup onClose={closeHint}>
          <p>{currentHint.body}</p>
        </HintPopup>
        // <div className={styles.hint}>
        //   <div className={styles.hintHeader}>
        //     <div className={styles.hintHeaderIcon}>
        //       <img src={HintButtonSvg} alt="" />
        //     </div>
        //     {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        //     <img
        //       className={styles.hintHeaderClose}
        //       src={CloseButtonSvg}
        //       alt=""
        //       onClick={closeHint}
        //     />
        //   </div>
        //   {currentHint.title && <h3>{currentHint.title}</h3>}
        //   <p>{currentHint.body}</p>
        //   {currentHint.image && (
        //     <div
        //       className={styles.hintImage}
        //       style={{
        //         backgroundImage: `url(${process.env.PUBLIC_URL}/images/${currentHint.image}`,
        //       }}
        //     />
        //   )}
        // </div>
      )}
    </div>
  );
}

export default Hint;
