import { useSelector } from "react-redux";

import { getStartTime } from "../../../store/reducers";
import CountdownClock from "../../ui/countdownClock/countdownClock";
import styles from "./clockAsset.module.scss";

function ClockAsset({ content }) {
  const startTime = useSelector(getStartTime);

  return (
    <div className={styles.clockAsset} style={content.position}>
      <CountdownClock
        startTime={startTime}
        totalTime={45 * 60}
        type={content.clockType}
      />
    </div>
  );
}

export default ClockAsset;
