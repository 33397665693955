import startImage from "../../assets/start/background_gradient.jpg";
import radialGlow from "../../assets/start/start_glow.png";
import logoAsset from "../../assets/ui/skip_productie.png";

const content = {
  id: "start",
  redirect: "/cutscene1",
  type: "image",
  image: startImage,
  size: {
    width: 560,
    height: 840,
  },
  objects: [
    {
      id: "greenLights",
      type: "animation",
      trigger: {
        image: radialGlow,
        position: {
          top: 20,
          left: 100,
        },
      },
    },
    {
      id: "logo",
      type: "imageAsset",
      image: logoAsset,
      position: {
        top: 700,
        left: 403,
      },
    },
  ],
};

export default content;
