import cn from "classnames";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import WordBgAsset from "../../../../assets/activities/activity_hit.png";
import ErrorAsset from "../../../../assets/activities/error.png";
import ShooterBgAsset from "../../../../assets/activities/shooter_bg.png";
import SmileyAsset from "../../../../assets/activities/smiley.png";
import lottieLine from "../../../../assets/lottie/arrowrow.json";
import { INVENTORY_PUZZLE_2_BUTTON_X } from "../../../../content/inventory/inventoryItems";
import { setUnlocked } from "../../../../store/actions/app";
import soundEffects, {
  SOUND_EFFECT_SUCCESS,
  SOUND_EFFECT_WRONG,
} from "../../../../utilities/soundEffects";
import ErrorOverlay from "../../../ui/errorOverlay/errorOverlay";
import LottieAsset from "../../lottieAsset/lottieAsset";
import styles from "./activitiesShooter.module.scss";

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const WRONG_ACTIVITIES = [
  { id: 2, title: "Blowen", error: true },
  { id: 4, title: "Stoned", error: true },
  { id: 6, title: "Joint", error: true },
  { id: 8, title: "Smoken", error: true },
  { id: 10, title: "Cannabis", error: true },
  { id: 21, title: "Sloom", error: true },
];

function FallingWord({ label, fall, onSuccess, onError, isBadWord }) {
  const startTop = getRandomArbitrary(-500, -50);
  const [toPosition, setToPosition] = useState(startTop);
  const [startLeft, setStartLeft] = useState(startTop);
  const [hit, setHit] = useState(false);

  useEffect(() => {
    const newStartLeft = getRandomArbitrary(10, 350);
    setStartLeft(newStartLeft);
  }, []);

  useEffect(() => {
    if (fall) {
      const endTop = getRandomArbitrary(500, 800);
      setToPosition(endTop);
    }
  }, [fall]);

  function handleClick() {
    setHit(true);
    if (isBadWord) {
      onError();
    } else {
      onSuccess();
    }
  }

  const fallingWordStyles = cn(styles.fallingWord, {
    [styles.hit]: hit,
  });
  return (
    <button
      type="button"
      className={fallingWordStyles}
      style={{ top: `${toPosition}px`, left: `${startLeft}px` }}
      onMouseDown={handleClick}
      onTouchStart={handleClick}
    >
      {label}
      <img src={WordBgAsset} alt="" />
    </button>
  );
}

function ActivitiesShooter({ selectedActivities, start, onFail }) {
  const canvas = useRef();
  const [itemToFall, setItemToFall] = useState(1);
  const [score, setScore] = useState(0);
  const [instructions, setInstructions] = useState(true);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [activities, setActivities] = useState([]);
  const dispatch = useDispatch();

  const [timer, setTimer] = useState(30);
  const resultTimer = useRef();

  useEffect(() => {
    const activityPool = [
      ...selectedActivities,
      ...WRONG_ACTIVITIES,
      ...selectedActivities,
      ...WRONG_ACTIVITIES,
      ...selectedActivities,
      ...WRONG_ACTIVITIES,
    ];

    setActivities(shuffle(activityPool));
  }, [selectedActivities]);

  useEffect(() => {
    let spawnTimer;
    if (start && !instructions) {
      setItemToFall(0);
      setScore(0);
      const startTime = moment().unix();

      spawnTimer = setInterval(() => {
        setItemToFall((i) => i + 1);
        const secondsPlayed = moment().unix() - startTime;
        const secondsLeft = 30 - secondsPlayed;
        if (secondsLeft <= 0) {
          setInstructions(true);
        }
        setTimer(`0${secondsLeft}`.slice(-2));
      }, 1000);
    }
    return () => clearInterval(spawnTimer);
  }, [start, onFail, instructions]);

  function handleSuccess() {
    const newScore = score + 1;
    setSuccess(true);
    setScore(newScore);
    soundEffects.playSoundEffect(SOUND_EFFECT_SUCCESS);
    if (newScore === 10) {
      dispatch(setUnlocked(INVENTORY_PUZZLE_2_BUTTON_X));
    }
    resultTimer.current = setTimeout(() => {
      setSuccess(false);
    }, 500);
  }

  function handleError() {
    setError(true);
    setScore(score - 1);
    soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
    resultTimer.current = setTimeout(() => {
      setError(false);
    }, 500);
  }

  const smileyStyles = cn(styles.smiley, {
    [styles.success]: success,
  });
  const errorIconStyles = cn(styles.errorIcon, {
    [styles.error]: error,
  });
  return (
    <div className={styles.activitiesShooter}>
      <div className={styles.activitiesShooterHeader}>
        <div className={styles.activitiesShooterTimer}>00:{timer}</div>
        <LottieAsset
          content={{
            animation: lottieLine,
            position: {
              top: 45,
              left: 170,
              width: 222,
            },
          }}
        />
        <div className={styles.activitiesShooterScore}>{score}</div>
      </div>
      <div className={styles.shooterContainer}>
        <img src={ShooterBgAsset} className={styles.background} alt="" />
        {instructions && (
          <div className={styles.instructions}>
            <p>Klik 10 keer op jouw gekozen activiteiten binnen 30 seconden.</p>
            <button
              type="submit"
              className={styles.button}
              onClick={() => setInstructions(false)}
            >
              Start!
            </button>
          </div>
        )}
        {!instructions && (
          <div ref={canvas} className={styles.canvas}>
            {activities.map((item, index) => (
              <FallingWord
                key={index}
                id={item.id}
                label={item.title}
                fall={index === itemToFall}
                isBadWord={item.error}
                onSuccess={handleSuccess}
                onError={handleError}
              />
            ))}
          </div>
        )}
      </div>
      <img src={SmileyAsset} alt="" className={smileyStyles} />
      <img src={ErrorAsset} alt="" className={errorIconStyles} />
      {error && <ErrorOverlay />}
    </div>
  );
}

export default ActivitiesShooter;
