import cn from "classnames";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import InputAsset from "../../../assets/mazeMap/input_goal.png";
import MazeStartToA from "../../../assets/mazeMap/maze_A.png";
import MazeStartToB from "../../../assets/mazeMap/maze_B.png";
import MazeStartAsset from "../../../assets/mazeMap/maze_start.png";
import MazeStartToX from "../../../assets/mazeMap/maze_X.png";
import MazeStartToY from "../../../assets/mazeMap/maze_Y.png";
import { getPersonalGoal } from "../../../store/reducers";
import BackMenu from "../../ui/menu/backMenu";
import styles from "./mazeMap.module.scss";

function MazeMap({ open, onClose }) {
  const personalGoal = useSelector(getPersonalGoal);
  const location = useLocation();
  const mazeMapDetailPageStyles = cn(styles.mazeMapDetailPage, {
    [styles.open]: open,
  });

  return (
    <div className={mazeMapDetailPageStyles}>
      <div className={styles.goalInputText}>
        <img alt="" src={InputAsset} />
        <textarea
          className={styles.textareaField}
          rows={2}
          defaultValue={personalGoal}
          readOnly
        />
      </div>
      <div className={styles.mazeNumber}>
        {location.pathname === "/room1" && <span>#1</span>}
        {location.pathname === "/room2" && <span>#2</span>}
        {location.pathname === "/room3" && <span>#3</span>}
        {location.pathname === "/room4" && <span>#4</span>}
        {location.pathname === "/room5" && <span>#5</span>}
      </div>
      <div className={styles.mazeAsset}>
        {location.pathname === "/room1" && <img alt="" src={MazeStartAsset} />}
        {location.pathname === "/room2" && <img alt="" src={MazeStartToA} />}
        {location.pathname === "/room3" && <img alt="" src={MazeStartToB} />}
        {location.pathname === "/room4" && <img alt="" src={MazeStartToX} />}
        {location.pathname === "/room5" && <img alt="" src={MazeStartToY} />}
      </div>
      <div className={styles.mapMenu}>
        <BackMenu handleClose={onClose} />
      </div>
    </div>
  );
}

export default MazeMap;
