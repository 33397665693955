// eslint-disable-next-line import/no-named-as-default
import Colour, { assertIsColour } from "./Colour";

// const levels = [
//   [3, 3, "a1abc2bc"],
//   [8, 8, "2b7gfe5d10d1f3b1g2e1c2ca1h3a5h"],
//   [15, 15, "4o1i9l6a11eb5g5e20j2g26n9f1f9l7h1o7n10j7c6pd3mc4h5p6im3kad23b2k]"],
//   [6, 5, "1d2a4b2b1c2c10da"],
// ];

const level = [6, 5, "1d2a4b2b1c2c10da"];

const loadFromString = (height, width, gridDefinition) => {
  const matches = gridDefinition.match(/\d+|[a-z]/gi);

  if (matches === null) {
    throw new TypeError("Invalid level definition");
  }

  const cells = matches.flatMap((value) => {
    if (/^\d+$/.test(value)) {
      return new Array(parseInt(value, 10)).fill(Colour.NONE);
    }

    if (/^[a-z]$/i.test(value)) {
      return assertIsColour(value);
    }

    throw new TypeError(`Unknown level data '${value}'.`);
  });

  const area = height * width;

  if (cells.length < area) {
    cells.push(...new Array(area - cells.length).fill(Colour.NONE));
  }

  return [height, width, cells];
};

export class LevelProvider {
  #location;

  constructor(location) {
    this.#location = location;
  }

  // eslint-disable-next-line class-methods-use-this
  generate() {
    return loadFromString(...level);
  }

  // fromURL() {
  //   const hashData = decodeURIComponent(this.#location.hash.slice(1));
  //
  //   if (hashData.length < 2) {
  //     return null;
  //   }
  //
  //   let jsonData;
  //
  //   try {
  //     jsonData = atob(hashData);
  //   } catch (e) {
  //     if (!(e instanceof DOMException)) {
  //       throw e;
  //     }
  //
  //     jsonData = hashData;
  //   }
  //
  //   if (!jsonData) {
  //     return null;
  //   }
  //
  //   let rawHashData;
  //
  //   try {
  //     rawHashData = JSON.parse(jsonData.replace(/'/g, '"'));
  //   } catch (e) {
  //     if (!(e instanceof SyntaxError)) {
  //       throw e;
  //     }
  //
  //     console.warn(`Invalid JSON data provided: ${jsonData}`);
  //     return null;
  //   }
  //
  //   if (
  //     !(rawHashData instanceof Array) ||
  //     rawHashData.length !== 3 ||
  //     typeof rawHashData[0] !== "number" ||
  //     typeof rawHashData[1] !== "number" ||
  //     typeof rawHashData[2] !== "string"
  //   ) {
  //     return null;
  //   }
  //
  //   const [height, width, levelString] = rawHashData;
  //
  //   return loadFromString(height, width, levelString);
  // }
}

export default LevelProvider;
