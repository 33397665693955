import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AssetLeftArrow from "../../../../assets/room4/arrow_left_square.png";
import ArrowAsset from "../../../../assets/ui/arrow-right.png";
import { INVENTORY_PUZZLE_2_BUTTON_Y } from "../../../../content/inventory/inventoryItems";
import { setObjectValue, setUnlocked } from "../../../../store/actions/app";
import { getObjectValues } from "../../../../store/reducers";
import styles from "./questionForm.module.scss";

function QuestionForm() {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [question1Value, setQuestion1Value] = useState("");
  const [question2Value, setQuestion2Value] = useState("");
  const [question3Value, setQuestion3Value] = useState("");
  const [question4Value, setQuestion4Value] = useState("");
  const [question5Value, setQuestion5Value] = useState("");
  const [question6Value, setQuestion6Value] = useState("");
  const [questionPlaceholder, setQuestionPlaceholder] = useState("");

  const dispatch = useDispatch();
  const objectValues = useSelector(getObjectValues);

  const SCREEN_WIDTH = 560;

  useEffect(() => {
    const activities = objectValues.find(
      (item) => item.id === "SELECTED_ACTIVITIES"
    );

    if (activities && activities.value.length > 0) {
      const array = activities.value.map((activity) => activity.title);
      setQuestionPlaceholder(array.toString().replace(",", ", "));
    }
  }, [objectValues]);

  function previousSlide() {
    const newIndex = currentSlideIndex - 1;
    setCurrentSlideIndex(newIndex);
  }

  function earnButton() {
    dispatch(
      setObjectValue({
        id: "FORM_VALUES",
        value: {
          question1: question1Value,
          question2: question2Value,
          question3: question3Value,
          question4: question4Value,
          question5: question5Value,
          question6: question6Value,
        },
      })
    );

    dispatch(setUnlocked(INVENTORY_PUZZLE_2_BUTTON_Y));
  }

  return (
    <div className={styles.questionFormCarouselContainer}>
      <div className={styles.questionFormCarousel}>
        <div
          className={styles.questionFormContainer}
          style={{
            transform: `translateX(-${SCREEN_WIDTH * currentSlideIndex}px)`,
          }}
        >
          <div className={styles.questionFormSlide}>
            <div className={styles.form}>
              <h2>Ik wil mijn persoonlijke doel halen omdat &gt; </h2>
              <textarea
                placeholder="..."
                onChange={(event) => {
                  setQuestion1Value(event.target.value);
                }}
              />
              <button
                className={styles.submit}
                type="submit"
                disabled={question1Value.length === 0}
                onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
              >
                <img alt="submit" src={ArrowAsset} />
              </button>
            </div>
          </div>
          <div className={styles.questionFormSlide}>
            <div className={styles.form}>
              <h2>Het kan voor mij moeilijk zijn, door &gt; </h2>
              <textarea
                placeholder="..."
                onChange={(event) => {
                  setQuestion2Value(event.target.value);
                }}
              />
              <div className={styles.formNavigation}>
                <button
                  type="button"
                  className={styles.previous}
                  onClick={previousSlide}
                >
                  <img src={AssetLeftArrow} alt="" />
                </button>
                <button
                  className={styles.submit}
                  type="submit"
                  disabled={question2Value.length === 0}
                  onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
                >
                  <img alt="submit" src={ArrowAsset} />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.questionFormSlide}>
            <div className={styles.form}>
              <h2>
                Maar op deze manier zorg ik ervoor dat het mij wél gaat lukken
                &gt;{" "}
              </h2>
              <textarea
                placeholder="..."
                onChange={(event) => {
                  setQuestion3Value(event.target.value);
                }}
              />
              <div className={styles.formNavigation}>
                <button
                  type="button"
                  className={styles.previous}
                  onClick={previousSlide}
                >
                  <img src={AssetLeftArrow} alt="" />
                </button>
                <button
                  className={styles.submit}
                  type="submit"
                  disabled={question3Value.length === 0}
                  onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
                >
                  <img alt="submit" src={ArrowAsset} />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.questionFormSlide}>
            {" "}
            <div className={styles.form}>
              <h2>
                Wie kan mij helpen bij het behalen van mijn persoonlijk doel
                &gt;{" "}
              </h2>
              <textarea
                placeholder="ouder, verzorger, vriend(in), leraar, baas"
                onChange={(event) => {
                  setQuestion4Value(event.target.value);
                }}
              />
              <div className={styles.formNavigation}>
                <button
                  type="button"
                  className={styles.previous}
                  onClick={previousSlide}
                >
                  <img src={AssetLeftArrow} alt="" />
                </button>
                <button
                  className={styles.submit}
                  type="submit"
                  disabled={question4Value.length === 0}
                  onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
                >
                  <img alt="submit" src={ArrowAsset} />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.questionFormSlide}>
            {" "}
            <div className={styles.form}>
              <h2>
                Een handige eigenschap van mij die helpt mijn doel te halen, is
                &gt;{" "}
              </h2>
              <textarea
                placeholder="creatief, positief, geduldig, sociaal, zelfvertrouwen, verantwoordelijk, sportief, etc."
                onChange={(event) => {
                  setQuestion5Value(event.target.value);
                }}
              />
              <div className={styles.formNavigation}>
                <button
                  type="button"
                  className={styles.previous}
                  onClick={previousSlide}
                >
                  <img src={AssetLeftArrow} alt="" />
                </button>
                <button
                  className={styles.submit}
                  type="submit"
                  disabled={question5Value.length === 0}
                  onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
                >
                  <img alt="submit" src={ArrowAsset} />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.questionFormSlide}>
            {" "}
            <div className={styles.form}>
              <h2>Om me goed te voelen, ga ik &gt; </h2>
              <textarea
                placeholder={questionPlaceholder}
                onChange={(event) => {
                  setQuestion6Value(event.target.value);
                }}
              />
              <div className={styles.formNavigation}>
                <button
                  type="button"
                  className={styles.previous}
                  onClick={previousSlide}
                >
                  <img src={AssetLeftArrow} alt="" />
                </button>
                <button
                  className={styles.submit}
                  type="submit"
                  disabled={question6Value.length === 0}
                  onClick={earnButton}
                >
                  <img alt="submit" src={ArrowAsset} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionForm;
