import cn from "classnames";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import HintBgAsset from "../../../../assets/activities/shooter_bg.png";
import lottieLine from "../../../../assets/lottie/arrowrow.json";
import RestartAsset from "../../../../assets/room5/restart.png";
import { getObjectValues } from "../../../../store/reducers";
import LottieAsset from "../../lottieAsset/lottieAsset";
import styles from "./wordPulseHint.module.scss";

const WORDS = [
  { title: "BOKSEN", color: "none" },
  { title: "FREERUNNING", color: "none" },
  { title: "SPORTEN", color: "yellow" },
  { title: "FIETSEN", color: "green" },
  { title: "KOKEN", color: "red" },
  { title: "TELEFONEREN", color: "blue" },
  { title: "KOKEN", color: "red" },
];

function PulsingWord({ word, color, active = false }) {
  const pulsingWordStyles = cn(styles.pulsingWord, {
    [styles.blue]: color === "blue",
    [styles.yellow]: color === "yellow",
    [styles.red]: color === "red",
    [styles.green]: color === "green",
    [styles.hide]: !active,
  });
  return (
    <div className={pulsingWordStyles}>
      <span>{word}</span>
    </div>
  );
}

function WordPulseHint() {
  const canvas = useRef();
  const [timer, setTimer] = useState(21);
  const [activeWordIndex, setActiveWordIndex] = useState(0);
  const [restart, setRestart] = useState(false);
  const [startTime, setStartime] = useState(false);
  const [pulsingWords, setPulsingWords] = useState(WORDS);
  const objectValues = useSelector(getObjectValues);

  useEffect(() => {
    const newPulsingWords = WORDS;
    const activities = objectValues.find(
      (item) => item.id === "SELECTED_ACTIVITIES"
    );

    if (activities && activities.value.length > 0) {
      if (activities.value[0]) {
        newPulsingWords[2].title = activities.value[0].title;
      }
      if (activities.value[1]) {
        newPulsingWords[3].title = activities.value[1].title;
      }
      if (activities.value[2]) {
        newPulsingWords[4].title = activities.value[2].title;
      }
      if (activities.value[3]) {
        newPulsingWords[5].title = activities.value[3].title;
      }
      if (activities.value[4]) {
        newPulsingWords[6].title = activities.value[4].title;
      }
    }

    setPulsingWords(newPulsingWords);
  }, [objectValues]);

  useEffect(() => {
    const newStartTime = moment().unix();
    setStartime(newStartTime);
    const countdowntimer = setInterval(() => {
      const secondsPlayed = moment().unix() - startTime;
      const secondsLeft = 21 - secondsPlayed;
      if (secondsLeft < 0) {
        setRestart(true);
      } else {
        setTimer(`0${secondsLeft}`.slice(-2));
      }
    }, 1000);
    return () => clearInterval(countdowntimer);
  }, [startTime]);

  useEffect(() => {
    const spawnTimer = setInterval(() => {
      setActiveWordIndex((i) => i + 1);
    }, 3000);
    return () => clearInterval(spawnTimer);
  }, []);

  useEffect(() => {
    if (!restart) {
      setTimer(21);
      setActiveWordIndex(0);
      const newStartTime = moment().unix();
      setStartime(newStartTime);
    }
  }, [restart]);

  return (
    <div className={styles.wordPulseHint}>
      <div className={styles.wordPulseHintHeader}>
        <div className={styles.wordPulseHintTimer}>00:{timer}</div>
        <LottieAsset
          content={{
            animation: lottieLine,
            position: {
              top: 45,
              left: 170,
              width: 222,
            },
          }}
        />
      </div>
      <div className={styles.hintContainer}>
        <img src={HintBgAsset} className={styles.background} alt="" />
        <div ref={canvas} className={styles.canvas}>
          {!restart &&
            pulsingWords.map((word, index) => (
              <PulsingWord
                key={index}
                word={word.title}
                color={word.color}
                active={activeWordIndex === index}
              />
            ))}
          {restart && (
            <button
              type="button"
              className={styles.restart}
              onClick={() => setRestart(false)}
            >
              <img src={RestartAsset} alt="restart button" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default WordPulseHint;
