import { RESET_STORE } from "../actions/app";
import {
  CLEAR_PHONE_EVENTS,
  SET_BATTERY_REMINDER_SEEN,
  SET_INVENTORY_ANIMATION_EVENT,
  SET_PHONE_EVENT,
  SET_PHONE_SEEN_EVENT,
  SET_PROGRESS_ANIMATION_EVENT,
} from "../actions/events";

const initialState = {
  phone: [],
  phoneSeen: [],
  batteryReminderSeen: [],
  inventoryAnimation: false,
  progressAnimation: false,
};

const events = (state = initialState, action) => {
  let event;
  let eventSeen;

  switch (action.type) {
    case SET_PHONE_EVENT:
      event = state.phone.find(
        (phoneEvent) => phoneEvent.id === action.payload.id
      );
      if (event) {
        return state;
      }

      return {
        ...state,
        phone: [...state.phone, action.payload],
      };
    case SET_PHONE_SEEN_EVENT:
      eventSeen = state.phoneSeen.find(
        (phoneEvent) => phoneEvent.id === action.payload.id
      );
      if (eventSeen) {
        return state;
      }

      return {
        ...state,
        phoneSeen: [...state.phoneSeen, action.payload],
      };
    case SET_BATTERY_REMINDER_SEEN:
      eventSeen = state.batteryReminderSeen.find(
        (batteryEvent) => batteryEvent.id === action.payload.id
      );
      if (eventSeen) {
        return state;
      }

      return {
        ...state,
        batteryReminderSeen: [...state.batteryReminderSeen, action.payload],
      };
    case SET_INVENTORY_ANIMATION_EVENT:
      return {
        ...state,
        inventoryAnimation: action.payload,
      };
    case SET_PROGRESS_ANIMATION_EVENT:
      return {
        ...state,
        progressAnimation: action.payload,
      };
    case CLEAR_PHONE_EVENTS:
      return {
        ...state,
        phone: [],
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export const getPhone = (state) => state.phone;
export const getPhoneSeen = (state) => state.phoneSeen;
export const getInventoryAnimation = (state) => state.inventoryAnimation;
export const getProgressAnimation = (state) => state.progressAnimation;
export const getBatteryReminderSeen = (state) => state.batteryReminderSeen;

export default events;
