import { Point } from "../components/Point";
import { Colour } from "./Colour";

export const isNeighbour = (cell, otherCell, height, width) => {
  const x = (cell.index() % width) - (otherCell.index() % width);
  const y =
    Math.floor(cell.index() / width) - Math.floor(otherCell.index() / width);

  if (x === -1 && y === 0) {
    return "l";
  }

  if (x === 1 && y === 0) {
    return "r";
  }

  if (x === 0 && y === -1) {
    return "t";
  }

  if (x === 0 && y === 1) {
    return "b";
  }

  return false;
};

export const Status = {
  DRAF: "DRAFT",
  FINAL: "FINAL",
  COMPLETE: "COMPLETE",
};

export class Path {
  #cells = [];

  #colour;

  #grid;

  #status = Status.DRAFT;

  constructor(colour, grid) {
    this.#colour = colour;
    this.#grid = grid;
  }

  breakAt(cell) {
    if (!this.includes(cell)) {
      return;
    }

    const breakIndex = this.#cells.indexOf(cell);
    const startChain = this.#cells.slice(0, breakIndex);
    const endChain = this.#cells.slice(breakIndex);

    if (
      endChain.length > startChain.length &&
      this.first() instanceof Point &&
      this.last() instanceof Point
    ) {
      this.#cells.reverse();
    }

    while (this.last() !== cell) {
      this.pop();
    }
  }

  canAdd(cell) {
    const last = this.last();

    if (last === null) {
      return cell instanceof Point;
    }

    if (this.complete()) {
      return false;
    }

    return (
      this.isNeighbour(last, cell) &&
      (!(cell instanceof Point) || cell.colour() === this.colour())
    );
  }

  clear() {
    while (this.last()) {
      this.pop();
    }
  }

  colour() {
    return this.#colour;
  }

  complete() {
    const first = this.first();
    const last = this.last();

    return (
      this.length() > 1 &&
      first &&
      last &&
      first !== last &&
      first instanceof Point &&
      last instanceof Point
    );
  }

  first() {
    if (this.length() === 0) {
      return null;
    }

    return this.#cells[0];
  }

  includes(cell) {
    return this.#cells.includes(cell);
  }

  isNeighbour(cell, otherCell) {
    return isNeighbour(
      cell,
      otherCell,
      this.#grid.height(),
      this.#grid.width()
    );
  }

  last() {
    if (this.length() === 0) {
      return null;
    }

    return this.#cells[this.length() - 1];
  }

  length() {
    return this.#cells.length;
  }

  pop() {
    if (this.length() === 0) {
      return;
    }

    const wasComplete = this.complete();
    const cell = this.#cells.pop();
    const last = this.last();

    if (!(cell instanceof Point)) {
      cell.setColour(Colour.NONE);
    }

    cell.setFinal(false);

    if (last) {
      last.dropConnection(this.isNeighbour(cell, last));
      cell.dropConnection(this.isNeighbour(last, cell));
    }

    if (wasComplete) {
      cell.setFinal(false);
      this.#cells.forEach((newCell) => newCell.setFinal(false));
      this.setStatus(Status.DRAFT);
    }
  }

  push(cell) {
    const last = this.last();

    if (last && !this.canAdd(cell)) {
      return;
    }

    this.#cells.push(cell);

    if (!(cell instanceof Point)) {
      cell.setColour(this.#colour);
    }

    if (last) {
      last.addConnection(this.isNeighbour(cell, last));
      cell.addConnection(this.isNeighbour(last, cell));
    }
  }

  setStatus(status) {
    if (this.#status === Status.COMPLETE && status === Status.FINAL) {
      return;
    }

    this.#status = status;

    if (status === Status.DRAFT) {
      this.#cells.forEach((cell) => cell.setFinal(false));

      return;
    }

    this.#cells.forEach((cell) => cell.setFinal(true));
  }

  status() {
    return this.#status;
  }
}

export default Path;
