import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import PanelAsset from "../../../assets/paternInput/panel.png";
import ArrowAsset from "../../../assets/ui/arrow-right.png";
import { getDebugStatus } from "../../../store/reducers";
import { debugValue } from "../../../utilities/debugValue";
import soundEffects, {
  SOUND_EFFECT_SUCCESS,
  SOUND_EFFECT_WRONG,
} from "../../../utilities/soundEffects";
import styles from "./numberInput.module.scss";

const NUMBER_INPUT = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function NumberButton({ onClick, buttonValue, selected }) {
  const overlayStyles = cn(styles.overlay, {
    [styles.selected]: selected,
    [styles.error]: false,
    [styles.success]: false,
  });

  return (
    <button
      type="button"
      className={styles.panel}
      onClick={() => onClick(buttonValue)}
    >
      <img src={PanelAsset} alt={`nummer knop ${buttonValue}`} />
      <div className={overlayStyles} />
      <span>{buttonValue}</span>
    </button>
  );
}

function NumberInput({
  content,
  onCodeUnlocked,
  onError,
  singleSelect = false,
}) {
  const debugMode = useSelector(getDebugStatus);
  const [value, setValue] = useState(
    debugValue("", content.code.toString(), debugMode)
  );
  const [error, setError] = useState(false);
  const [, setSuccess] = useState(false);

  const timer = useRef();

  useEffect(() => () => timer.current && clearTimeout(timer.current), []);

  function handleError() {
    setError(true);
    onError(true);
    soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
    setValue("");
    timer.current = setTimeout(() => {
      setError(false);
      onError(false);
    }, 1500);
  }

  function onSuccess() {
    setSuccess(true);
    soundEffects.playSoundEffect(SOUND_EFFECT_SUCCESS);
    timer.current = setTimeout(() => {
      onCodeUnlocked(content.success);
      setValue("");
    }, 1000);
  }

  function checkValue() {
    if (value.toString() === content.code.toString()) {
      onSuccess();
    } else {
      handleError();
    }
  }

  function onKeyPress(key) {
    if (error) {
      soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);
      return;
    }
    if (key !== "back" && key !== "enter" && value.length < 4) {
      if (singleSelect) {
        setValue(`${key}`);
      } else {
        setValue(`${value}${key}`);
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.numberInput}>
        {NUMBER_INPUT.map((input, index) => (
          <NumberButton
            key={index}
            buttonValue={index + 1}
            selected={value.includes(index + 1)}
            onClick={onKeyPress}
          />
        ))}
      </div>
      <button
        className={styles.submit}
        type="submit"
        disabled={value.length === 0}
        onClick={checkValue}
      >
        <img alt="submit" src={ArrowAsset} />
      </button>
    </div>
  );
}

export default NumberInput;
