import { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import styles from "./App.module.scss";
import routes from "./routes";
import createStore from "./store";

const { store, persistor } = createStore();
export const SCREEN_WIDTH = 560;
export const SCREEN_HEIGHT = 840;

function App() {
  const appRef = useRef();

  function handleResize() {
    if (!appRef.current) return;
    if (window.outerWidth / window.outerHeight > SCREEN_WIDTH / SCREEN_HEIGHT) {
      const ratio = window.outerHeight / SCREEN_HEIGHT;
      appRef.current.style.transform = `scale(${ratio}) translate(-50%, -50%)`;
    } else {
      const ratio = window.outerWidth / SCREEN_WIDTH;
      appRef.current.style.transform = `scale(${ratio}) translate(-50%, -50%)`;
    }
  }

  useEffect(() => {
    handleResize();
  }, [appRef]);

  window.addEventListener("resize", handleResize);

  const router = createBrowserRouter(routes);

  return (
    <div ref={appRef} className={styles.application}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
