import cn from "classnames";
import { useEffect, useState } from "react";

import PanelAsset from "../../../assets/paternInput/panel.png";
import styles from "./paternInput.module.scss";

const IDLE_COLORS = {
  fill: "transparent",
  stroke: "transparent",
  outline: "#FFFFFF",
};
const SELECTED_COLORS = {
  fill: "#7D45FF",
  stroke: "#9D91BB",
  outline: "#FFFFFF",
};
const SUCCESS_COLORS = {
  fill: "#29BD68",
  stroke: "#B4FFB2",
  outline: "#87DBAB",
};
const FAILED_COLORS = {
  fill: "#FF0000",
  stroke: "#FEB2B2",
  outline: "#FFA3A3",
};

function Dot({ selected, error, success }) {
  const [, setColors] = useState(IDLE_COLORS);
  useEffect(() => {
    if (selected && error) {
      setColors(FAILED_COLORS);
    } else if (selected && success) {
      setColors(SUCCESS_COLORS);
    } else if (selected) {
      setColors(SELECTED_COLORS);
    } else {
      setColors(IDLE_COLORS);
    }
  }, [selected, error, success]);

  const overlayStyles = cn(styles.overlay, {
    [styles.selected]: selected,
    [styles.error]: error,
    [styles.success]: success,
  });

  return (
    <div className={styles.panel}>
      <img src={PanelAsset} alt="" />
      <div className={overlayStyles} />
    </div>
  );
}

export default Dot;
