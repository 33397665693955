import cn from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";

import lottieLine from "../../../../assets/lottie/arrowrow.json";
import lottieCircle from "../../../../assets/lottie/radial.json";
import { setObjectValue } from "../../../../store/actions/app";
// import bgInputAsset from "../../../../assets/room2/patern_bg.png";
// import AssetLeftArrow from "../../../../assets/room4/arrow_left_square.png";
import NumberInput from "../../../input/numberInput/numberInput";
import SymbolInput from "../../../input/symbolInput/symbolInput";
import ErrorOverlay from "../../../ui/errorOverlay/errorOverlay";
import SubmitButton from "../../../ui/submitButton/submitButton";
import LottieAsset from "../../lottieAsset/lottieAsset";
import styles from "./activitiesMinigame.module.scss";
import ActivitiesShooter from "./activitiesShooter";

const ACTIVITIES = [
  { id: 1, title: "Muziek luisteren" },
  { id: 2, title: "Skaten" },
  { id: 3, title: "Klimmen" },
  { id: 4, title: "Gamen" },
  { id: 5, title: "Fietsen" },
  { id: 6, title: "Film kijken" },
  { id: 7, title: "Sporten" },
  { id: 8, title: "Tekenen" },
  { id: 10, title: "Voetbal" },
  { id: 11, title: "Dansen" },
  { id: 12, title: "Zwemmen" },
  { id: 13, title: "Fitness" },
  { id: 14, title: "Basketballen" },
  { id: 15, title: "Freerunning" },
  { id: 16, title: "Boksen" },
];

function ActivitiesMinigame() {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [generalError, setGeneralError] = useState(false);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const SCREEN_WIDTH = 560;

  function handleActivityClick(activity) {
    const selectedButtons = [...selected];
    const buttonIdIndex = selectedButtons.findIndex(
      (element) => element.id === activity.id
    );
    if (buttonIdIndex === -1) {
      selectedButtons.push(activity);
    } else {
      selectedButtons.splice(buttonIdIndex, 1);
    }
    setSelected(selectedButtons);
  }

  function nextPage() {
    const newIndex = currentPageIndex + 1;
    setCurrentPageIndex(newIndex);
  }

  function handleSelectActivities() {
    dispatch(setObjectValue({ id: "SELECTED_ACTIVITIES", value: selected }));

    nextPage();
  }

  return (
    <div className={styles.activitiesContainer}>
      <div className={styles.activityCarousel}>
        <div
          className={styles.activityPageContainer}
          style={{
            transform: `translateX(-${SCREEN_WIDTH * currentPageIndex}px)`,
          }}
        >
          <div className={styles.activityPage}>
            <LottieAsset
              content={{
                animation: lottieCircle,
                position: {
                  top: 15,
                  left: 69,
                  width: 66,
                },
              }}
            />
            <LottieAsset
              content={{
                animation: lottieLine,
                position: {
                  top: 35,
                  left: 270,
                  width: 222,
                },
              }}
            />
            <div className={styles.codeInput}>
              <SymbolInput
                content={{ code: [4], inputPosition: {} }}
                onCodeUnlocked={nextPage}
                onError={setGeneralError}
              />
            </div>
          </div>
          <div className={styles.activityPage}>
            <LottieAsset
              content={{
                animation: lottieCircle,
                position: {
                  top: 15,
                  left: 69,
                  width: 66,
                },
              }}
            />
            <LottieAsset
              content={{
                animation: lottieLine,
                position: {
                  top: 35,
                  left: 270,
                  width: 222,
                },
              }}
            />
            <div className={styles.symbolInput}>
              <NumberInput
                content={{ code: [9], inputPosition: {} }}
                singleSelect
                onCodeUnlocked={nextPage}
                onError={setGeneralError}
              />
            </div>
          </div>
          <div className={styles.activityPage}>
            <div className={styles.activityListContainer}>
              <h1>Wat vind je leuk om te doen</h1>
              <p>Kies vijf activiteiten</p>
              {ACTIVITIES.map((activity, index) => {
                const buttonStyles = cn(styles.button, {
                  [styles.selected]: selected.find(
                    (element) => element.id === activity.id
                  ),
                });

                return (
                  <button
                    key={index}
                    type="button"
                    className={buttonStyles}
                    onClick={() => handleActivityClick(activity)}
                  >
                    {activity.title}
                  </button>
                );
              })}
              <SubmitButton
                callback={handleSelectActivities}
                disabled={selected.length !== 5}
              />
            </div>
          </div>
          <div className={styles.activityPage}>
            <ActivitiesShooter
              selectedActivities={selected}
              start={currentPageIndex === 3}
              onFail={() => setCurrentPageIndex(currentPageIndex - 1)}
            />
          </div>
        </div>
      </div>
      {generalError && <ErrorOverlay />}
    </div>
  );
}

export default ActivitiesMinigame;
