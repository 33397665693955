import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import ArrowAsset from "../../../assets/ui/arrow-right.png";
import { getDebugStatus } from "../../../store/reducers";
import { debugValue } from "../../../utilities/debugValue";
import soundEffects, {
  SOUND_EFFECT_CLICK,
} from "../../../utilities/soundEffects";
import ErrorOverlay from "../../ui/errorOverlay/errorOverlay";
import Dot from "./dot";
import styles from "./paternInput.module.scss";

const DOTS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function PaternInput({ content, onCodeUnlocked }) {
  const debugMode = useSelector(getDebugStatus);
  const { code } = content;
  const [enteredCode, setEnteredCode] = useState(
    debugValue([], code.slice(0, code.length - 1), debugMode)
  );
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const timer = useRef();

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    []
  );

  function clearTimer() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }

  function evaluateCode() {
    // Check part of code that is entered
    if (JSON.stringify(code) !== JSON.stringify(enteredCode)) {
      setError(true);
      clearTimer();
      timer.current = setTimeout(() => {
        setEnteredCode([]);
        setError(false);
      }, 5000);
    } else {
      setSuccess(true);
      soundEffects.playSoundEffect(SOUND_EFFECT_CLICK);
      onCodeUnlocked(content.success);
    }
  }

  function selectDot(value) {
    const dotNumber = enteredCode.findIndex((number) => number === value);
    let newCode;
    if (!error) {
      if (dotNumber === -1) {
        newCode = [...enteredCode];
        newCode.push(value);
      } else {
        newCode = [...enteredCode];
        newCode.splice(dotNumber, 1);
      }

      setEnteredCode(newCode.sort());
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.dots}>
        {DOTS.map((number, key) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            key={key}
            className={styles.dotContainer}
            onClick={() => selectDot(number)}
          >
            <Dot
              selected={enteredCode.find((value) => number === value)}
              success={success}
              error={error}
              number={number}
            />
          </div>
        ))}
      </div>
      <button
        className={styles.submit}
        type="submit"
        disabled={enteredCode.length === 0}
        onClick={evaluateCode}
      >
        <img alt="submit" src={ArrowAsset} />
      </button>
      {error && <ErrorOverlay />}
    </div>
  );
}

export default PaternInput;
