import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import progressAsset from "../../../assets/menu/batterijBackground.png";
import batteryAssetGreen from "../../../assets/menu/battery_green.png";
import batteryAssetOrange from "../../../assets/menu/battery_orange.png";
import batteryAssetRed from "../../../assets/menu/battery_red.png";
import { setBatteryReminderSeen } from "../../../store/actions/events";
import { getBatteryReminderSeen, getStartTime } from "../../../store/reducers";
import { secondsToClockTimeOnlyMinutes } from "../../../utilities/timeHelpers";
import HintPopup from "../hintPopup/hintPopup";
import styles from "./menu.module.scss";

const BATTERY_10_PERCENT = "BATTERY_10_PERCENT";

function ProgressPanel({ position }) {
  const startTime = useSelector(getStartTime);
  const batterySeenEvents = useSelector(getBatteryReminderSeen);
  const [top, left] = position;
  const totalTime = 45 * 60;
  const [minutesLeft, setMinutesLeft] = useState("00");
  const [progress, setProgress] = useState(0);

  const [batteryReminder, showBatteryReminder] = useState(undefined);

  const timer = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    function update() {
      const secondsSinceStart = moment().unix() - startTime;
      const secondsLeft = totalTime - secondsSinceStart;
      const minLeft = secondsToClockTimeOnlyMinutes(secondsLeft);
      setMinutesLeft(minLeft);
      const percentageLeft = Math.round((secondsLeft / totalTime) * 100);

      if (secondsLeft < 0) {
        setProgress(0);
      } else {
        const alreadySeenBattery10 = batterySeenEvents.find(
          (e) => e.id === BATTERY_10_PERCENT
        );
        if (percentageLeft < 10 && !alreadySeenBattery10) {
          dispatch(setBatteryReminderSeen({ id: BATTERY_10_PERCENT }));
          showBatteryReminder(true);
        }

        setProgress(percentageLeft);
      }
    }

    if (startTime) {
      timer.current = setInterval(() => {
        update();
      }, 1000);
      update();
    }

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [startTime, totalTime, batterySeenEvents, dispatch]);

  return (
    <>
      <div
        className={styles.progressPanel}
        style={{ top: `${top}px`, left: `${left}px` }}
      >
        <img src={progressAsset} alt="" />
        <div className={styles.progressBattery}>
          <div className={styles.time}>
            {minutesLeft}
            <span className={styles.smallLabel}>min</span>
          </div>
          {progress >= 66 && (
            <img src={batteryAssetGreen} alt="" width="40px" />
          )}
          {progress >= 33 && progress < 66 && (
            <img src={batteryAssetOrange} alt="" width="40px" />
          )}
          {progress < 33 && <img src={batteryAssetRed} alt="" width="40px" />}
        </div>
      </div>
      {batteryReminder && (
        <HintPopup onClose={() => showBatteryReminder(false)}>
          <p>JE HEBT NOG 10% BATTERIJ</p>
        </HintPopup>
      )}
    </>
  );
}

export default ProgressPanel;
