import briefOverview from "../../assets/background_gradient.jpg";
import video from "../../assets/cutscenes/gate_open_560_2_HB.mp4";
import lottieArrows from "../../assets/lottie/arrowrow.json";
import lottieLine from "../../assets/lottie/cubes3.json";
import lottieClick from "../../assets/lottie/interaction_frames.json";
import LottieLineScreen from "../../assets/lottie/lines.json";
import lottieCircle from "../../assets/lottie/radial.json";
// import roomIndicator from "../../assets/lottie/room2.json";
import gateControllerGlow from "../../assets/room1/gate_cont_LIGHT.png";
import doorLockDetail from "../../assets/room1/gate_controller.png";
import doorLock from "../../assets/room1/room1_gate.png";
import hintAsset from "../../assets/room2/export_hint.png";
import buttonVideo from "../../assets/room2/knopA_gevonden_HB.mp4";
import bgImage from "../../assets/room2/ROOM2.jpg";
import windowMinigameTrigger from "../../assets/room2/room2_9panel.png";
import panel1 from "../../assets/room2/room2_panel1_true.png";
import panel2 from "../../assets/room2/room2_panel2_true.png";
import panel3 from "../../assets/room2/room2_panel3_true.png";
import panel4 from "../../assets/room2/room2_panel4_true.png";
import panel5 from "../../assets/room2/room2_panel5_true.png";
import panel6 from "../../assets/room2/room2_panel6_true.png";
import panel7 from "../../assets/room2/room2_panel7_true.png";
import panel8 from "../../assets/room2/room2_panel8_true.png";
import panel9 from "../../assets/room2/room2_panel9_true.png";
// import roomGlow from "../../assets/room2/ROOM2_REFELCT.png";
import hintTrigger from "../../assets/room2/room2_unit_L.png";
import numberInputTrigger from "../../assets/room2/room2_unit_M.png";
import hintWindowTrigger from "../../assets/room2/room2_unit_R1.png";
import tunnelVision from "../../assets/room2/tunnelvisie_mask.png";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_2_BUTTON_A,
  INVENTORY_PUZZLE_2_PANEL_1,
  INVENTORY_PUZZLE_2_PANEL_2,
  INVENTORY_PUZZLE_2_PANEL_3,
  INVENTORY_PUZZLE_2_PANEL_4,
  INVENTORY_PUZZLE_2_PANEL_5,
  INVENTORY_PUZZLE_2_PANEL_6,
  INVENTORY_PUZZLE_2_PANEL_7,
  INVENTORY_PUZZLE_2_PANEL_8,
  INVENTORY_PUZZLE_2_PANEL_9,
} from "../inventory/inventoryItems";

const content = {
  id: "start",
  type: "image",
  image: bgImage,
  size: {
    width: 3964,
  },
  objects: [
    // {
    //   id: "roomIndicator",
    //   type: "lottie",
    //   animation: roomIndicator,
    //   noLoop: true,
    //   position: {
    //     top: -200,
    //     left: 0,
    //     width: 560,
    //   },
    // },
    {
      id: "hint",
      type: "scene",
      trigger: {
        image: hintTrigger,
        pulsing: true,
        position: {
          top: 295,
          left: 624,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "hintDetail",
          type: "hintWindowCarousel",
          position: {
            top: 115,
            left: 53,
          },
        },
        {
          id: "lineScreen",
          type: "lottie",
          animation: LottieLineScreen,
          position: {
            top: 0,
            left: 0,
            width: 560,
          },
        },
        {
          id: "greenLights",
          type: "lottie",
          animation: lottieArrows,
          position: {
            top: 36,
            left: 286,
            width: 222,
          },
        },
        {
          id: "cubes",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 30,
            left: 50,
            width: 135,
          },
        },
        {
          id: "cubes2",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 418,
            left: 267,
            width: 135,
          },
        },
      ],
    },
    {
      id: "clickAnimation",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 340,
        left: 705,
        width: 56,
      },
    },
    {
      id: "windowMinigame",
      type: "scene",
      trigger: {
        image: windowMinigameTrigger,
        pulsing: true,
        position: {
          top: 205,
          left: 1265,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "windows",
          type: "windowMinigame",
          position: {
            top: 115,
            left: 30,
          },
        },
      ],
    },
    {
      id: "window1",
      type: "imageAsset",
      image: panel1,
      showWhen: INVENTORY_PUZZLE_2_PANEL_1,
      position: {
        top: 222,
        left: 1315,
      },
    },
    {
      id: "window2",
      type: "imageAsset",
      image: panel2,
      showWhen: INVENTORY_PUZZLE_2_PANEL_2,
      position: {
        top: 222,
        left: 1433,
      },
    },
    {
      id: "window3",
      type: "imageAsset",
      image: panel3,
      showWhen: INVENTORY_PUZZLE_2_PANEL_3,
      position: {
        top: 222,
        left: 1549,
      },
    },
    {
      id: "window4",
      type: "imageAsset",
      image: panel4,
      showWhen: INVENTORY_PUZZLE_2_PANEL_4,
      position: {
        top: 335,
        left: 1315,
      },
    },
    {
      id: "window5",
      type: "imageAsset",
      image: panel5,
      showWhen: INVENTORY_PUZZLE_2_PANEL_5,
      position: {
        top: 335,
        left: 1433,
      },
    },
    {
      id: "window6",
      type: "imageAsset",
      image: panel6,
      showWhen: INVENTORY_PUZZLE_2_PANEL_6,
      position: {
        top: 335,
        left: 1549,
      },
    },
    {
      id: "window7",
      type: "imageAsset",
      image: panel7,
      showWhen: INVENTORY_PUZZLE_2_PANEL_7,
      position: {
        top: 444,
        left: 1315,
      },
    },
    {
      id: "window8",
      type: "imageAsset",
      image: panel8,
      showWhen: INVENTORY_PUZZLE_2_PANEL_8,
      position: {
        top: 444,
        left: 1433,
      },
    },
    {
      id: "window9",
      type: "imageAsset",
      image: panel9,
      showWhen: INVENTORY_PUZZLE_2_PANEL_9,
      position: {
        top: 444,
        left: 1549,
      },
    },
    {
      id: "clickAnimation2",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 355,
        left: 1458,
        width: 56,
      },
    },
    {
      id: "doorLock",
      type: "scene",
      locked: INVENTORY_CONTROLLER,
      trigger: {
        pulsing: true,
        image: doorLock,
        position: {
          top: 283,
          left: 3228,
        },
      },
      detail: {
        image: doorLockDetail,
        size: {
          width: 560,
        },
      },
      objects: [
        {
          id: "controller",
          type: "gateController",
          code: ["RIGHT", "DOWN", "UP", "LEFT", INVENTORY_PUZZLE_2_BUTTON_A],
          video,
          success: {
            type: "redirect",
            page: "/room3",
          },
        },
        {
          id: "controllerGlow",
          type: "animation",
          trigger: {
            image: gateControllerGlow,
            position: {
              top: 0,
              left: 0,
            },
          },
        },
      ],
    },
    {
      id: "numberInput",
      type: "scene",
      trigger: {
        image: numberInputTrigger,
        pulsing: true,
        position: {
          top: 287,
          left: 2167,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "lineScreen",
          type: "lottie",
          animation: LottieLineScreen,
          position: {
            top: 0,
            left: 0,
            width: 560,
          },
        },
        {
          id: "greenCircle",
          type: "lottie",
          animation: lottieCircle,
          position: {
            top: 40,
            left: 410,
            width: 66,
          },
        },
        {
          id: "greenLights",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 50,
            left: 80,
            width: 135,
          },
        },
        {
          id: "greenLight2s",
          type: "paternlock",
          code: [1, 2, 3, 4, 6, 9],
          success: {
            puzzle: INVENTORY_PUZZLE_2_BUTTON_A,
            type: "unlock",
          },
        },
        {
          id: "buttonAEarned",
          type: "videoAsset",
          showWhen: INVENTORY_PUZZLE_2_BUTTON_A,
          video: buttonVideo,
          closeOnEnd: true,
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "clickAnimation3",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 342,
        left: 2265,
        width: 56,
      },
    },
    {
      id: "hint",
      type: "scene",
      trigger: {
        image: hintWindowTrigger,
        pulsing: true,
        position: {
          top: 262,
          left: 1801,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "hintDetail",
          type: "imageAsset",
          image: hintAsset,
          position: {
            top: 115,
            left: 53,
          },
        },
        {
          id: "greenCircle",
          type: "lottie",
          animation: lottieCircle,
          position: {
            top: 157,
            left: 93,
            width: 66,
          },
        },
        {
          id: "greenLights",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 50,
            left: 55,
            width: 135,
          },
        },
        {
          id: "greenLight2s",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 575,
            left: 55,
            width: 135,
          },
        },
      ],
    },
    {
      id: "clickAnimation4",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 320,
        left: 1880,
        width: 56,
      },
    },
    // {
    //   id: "roomGlow",
    //   type: "animation",
    //   trigger: {
    //     image: roomGlow,
    //     isSlow: true,
    //     position: {
    //       top: 0,
    //       left: 0,
    //       width: 3964,
    //     },
    //   },
    // },
    {
      id: "tunnelVision",
      type: "imageAsset",
      staticPosition: true,
      hideWhen: INVENTORY_PUZZLE_2_BUTTON_A,
      image: tunnelVision,
      position: {
        top: 0,
        left: 0,
      },
    },
  ],
};

export default content;
