import { useState } from "react";

import AssetLeftArrow from "../../../assets/room4/arrow_left_square.png";
import AssetRightArrow from "../../../assets/room4/arrow_right_square.png";
import styles from "./hintCarousel.module.scss";

const hints = [
  {
    text: "Wist je dat... de meeste jongeren niet blowen",
  },
  {
    text: "Wist je dat... jongeren vaak denken dat anderen meer blowen dan ze eigenlijk doen  ",
  },
  {
    text: "Wist je dat...  je jezelf luier voelt door te blowen ",
  },
];

function Hint({ hint }) {
  return (
    <div className={styles.hint}>
      <p>{hint.text}</p>
    </div>
  );
}

function HintWindowCarousel() {
  const [currentHintIndex, setCurrentHintIndex] = useState(0);
  const SCREEN_WIDTH = 560;

  function previousHint() {
    const newIndex = currentHintIndex - 1;
    setCurrentHintIndex(newIndex);
  }

  function nextHint() {
    const newIndex = currentHintIndex + 1;
    setCurrentHintIndex(newIndex);
  }

  return (
    <div className={styles.hintCarouselContainer}>
      <div className={styles.hintCarousel}>
        <div
          className={styles.hintContainer}
          style={{
            transform: `translateX(-${SCREEN_WIDTH * currentHintIndex}px)`,
          }}
        >
          {hints.map((hint, key) => (
            <Hint key={key} hint={hint} />
          ))}
        </div>
      </div>
      <div className={styles.hintNavigation}>
        {currentHintIndex > 0 && (
          <button type="button" onClick={previousHint}>
            <img src={AssetLeftArrow} alt="" />
          </button>
        )}
        <div className={styles.symbol} />
        <span className={styles.filler} />
        {currentHintIndex < hints.length - 1 && (
          <button type="button" onClick={nextHint}>
            <img src={AssetRightArrow} alt="" />
          </button>
        )}
      </div>
    </div>
  );
}

export default HintWindowCarousel;
