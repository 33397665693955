import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import rootReducer from "./reducers";

const persistConfig = {
  key: "team_alert_escapabel",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeCreator = () => {
  const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default storeCreator;
