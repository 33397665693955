import moment from "moment";

import {
  ADD_TO_HINTS,
  ADD_TO_INVENTORY,
  ADD_TO_PUZZLES_SOLVED,
  REMOVE_UNLOCKED,
  RESET_STORE,
  SET_DEBUG_STATUS,
  SET_END_TIME,
  SET_MUTE_STATUS,
  SET_OBJECT_VALUE,
  SET_PERSONAL_GOAL,
  SET_PROGRESS,
  SET_ROOM_TIME,
  SET_START_TIME,
  SET_UNLOCKED,
} from "../actions/app";

const initialState = {
  inventory: [],
  hints: [],
  objectValues: [],
  unlocked: [],
  puzzlesSolved: [],
  mute: true,
  debug: false,
  roomTime: null,
  startTime: null,
  endTime: null,
  personalGoal: "",
  progress: 0,
  showHint: null,
};

const app = (state = initialState, action) => {
  let newHintsState;
  let unlockedIndex;
  let puzzleIndex;
  let resultIndex;
  let newObject;
  let newUnlockedArray;

  switch (action.type) {
    case ADD_TO_INVENTORY:
      if (state.inventory.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        inventory: [...state.inventory, action.payload],
      };
    case ADD_TO_HINTS:
      newHintsState = state.hints;
      if (action.payload && !state.hints.includes(action.payload)) {
        newHintsState = [...state.hints, action.payload];
      }
      return {
        ...state,
        hints: newHintsState,
        showHint: action.payload,
      };
    case SET_MUTE_STATUS:
      return {
        ...state,
        mute: action.payload,
      };
    case SET_DEBUG_STATUS:
      return {
        ...state,
        debug: action.payload,
      };
    case SET_PERSONAL_GOAL:
      return {
        ...state,
        personalGoal: action.payload,
      };
    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload ? action.payload : moment().unix(),
      };
    case SET_ROOM_TIME:
      return {
        ...state,
        roomTime: moment().unix(),
      };
    case SET_END_TIME:
      return {
        ...state,
        endTime: moment().unix(),
      };
    case SET_UNLOCKED:
      unlockedIndex = state.unlocked.findIndex((id) => id === action.payload);

      if (unlockedIndex !== -1) {
        return state;
      }
      return {
        ...state,
        unlocked: [...state.unlocked, action.payload],
      };
    case REMOVE_UNLOCKED:
      unlockedIndex = state.unlocked.findIndex((id) => id === action.payload);
      if (unlockedIndex === -1) {
        return state;
      }
      newUnlockedArray = [...state.unlocked];
      newUnlockedArray.splice(unlockedIndex, 1);

      return {
        ...state,
        unlocked: newUnlockedArray,
      };
    case ADD_TO_PUZZLES_SOLVED:
      puzzleIndex = state.puzzlesSolved.findIndex(
        (id) => id === action.payload
      );

      if (puzzleIndex !== -1) {
        return state;
      }
      return {
        ...state,
        puzzlesSolved: [...state.puzzlesSolved, action.payload],
      };

    case SET_OBJECT_VALUE:
      resultIndex = state.objectValues.findIndex(
        (objectIndex) => objectIndex.id === action.payload.id
      );

      if (resultIndex === -1) {
        return {
          ...state,
          objectValues: [...state.objectValues, action.payload],
        };
      }
      newObject = [...state.objectValues];
      newObject[resultIndex].value = action.payload.value;
      return {
        ...state,
        objectValues: newObject,
      };

    case SET_PROGRESS:
      return {
        ...state,
        progress: parseInt(action.payload, 10),
      };
    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export const getAppInventory = (state) => state.inventory;
export const getAppObjectValues = (state) => state.objectValues;
export const getAppMuteStatus = (state) => state.mute;
export const getAppStartTime = (state) => state.startTime;
export const getAppEndTime = (state) => state.endTime;
export const getAppRoomTime = (state) => state.roomTime;
export const getAppUnlocked = (state) => state.unlocked;
export const getAppDebugStatus = (state) => state.debug;
export const getAppPersonalGoal = (state) => state.personalGoal;
export const getAppProgress = (state) => state.progress;
export const getAppHints = (state) => state.hints;
export const getAppShowHint = (state) => state.showHint;
export const getAppPuzzlesSolved = (state) => state.puzzlesSolved;

export default app;
