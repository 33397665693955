import briefOverview from "../../assets/background_gradient.jpg";
import video from "../../assets/cutscenes/gate_open_560_3_HB.mp4";
import lottieAudio from "../../assets/lottie/audiowave.json";
import lottieLine from "../../assets/lottie/cubes3.json";
import lottieClick from "../../assets/lottie/interaction_frames.json";
// import roomIndicator from "../../assets/lottie/room3.json";
import gateControllerGlow from "../../assets/room1/gate_cont_LIGHT.png";
import doorLockDetail from "../../assets/room1/gate_controller.png";
import doorLock from "../../assets/room1/room1_gate.png";
import buttonVideo from "../../assets/room2/knopB_gevonden_HB.mp4";
import bgImage from "../../assets/room3/ROOM3.jpg";
import roomGlow from "../../assets/room3/ROOM3_REFLECT.png";
import flowMinigameTrigger from "../../assets/room3/room3_unit_L.png";
import chatBotTrigger from "../../assets/room3/room3_unit_R.png";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_2_BUTTON_B,
} from "../inventory/inventoryItems";

const content = {
  id: "start",
  type: "image",
  image: bgImage,
  size: {
    width: 3964,
  },
  objects: [
    // {
    //   id: "roomIndicator",
    //   type: "lottie",
    //   animation: roomIndicator,
    //   noLoop: true,
    //   position: {
    //     top: -200,
    //     left: 0,
    //     width: 560,
    //   },
    // },
    {
      id: "flowMinigame",
      type: "scene",
      trigger: {
        image: flowMinigameTrigger,
        pulsing: true,
        position: {
          top: 263,
          left: 1157,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "flowMinigame",
          type: "flowMinigame",
          position: {
            top: 115,
            left: 30,
          },
        },
      ],
    },
    {
      id: "clickAnimation1",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 320,
        left: 1273,
        width: 56,
      },
    },
    {
      id: "doorLock",
      type: "scene",
      locked: INVENTORY_CONTROLLER,
      trigger: {
        pulsing: true,
        image: doorLock,
        position: {
          top: 283,
          left: 3228,
        },
      },
      detail: {
        image: doorLockDetail,
        size: {
          width: 560,
        },
      },
      objects: [
        {
          id: "controller",
          type: "gateController",
          code: ["RIGHT", "UP", "RIGHT", "DOWN", INVENTORY_PUZZLE_2_BUTTON_B],
          video,
          success: {
            type: "redirect",
            page: "/room4",
          },
        },
        {
          id: "controllerGlow",
          type: "animation",
          trigger: {
            image: gateControllerGlow,
            position: {
              top: 0,
              left: 0,
            },
          },
        },
      ],
    },
    {
      id: "chatBot",
      type: "scene",
      trigger: {
        image: chatBotTrigger,
        pulsing: true,
        position: {
          top: 291,
          left: 1871,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "lottieLine",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 50,
            left: 384,
            width: 135,
          },
        },
        {
          id: "lottieAudio",
          type: "lottie",
          animation: lottieAudio,
          position: {
            top: 30,
            left: 40,
            width: 212,
          },
        },
        {
          id: "chatGame",
          type: "chatGame",
          position: {
            top: 0,
            left: 0,
          },
        },
        {
          id: "buttonBEarned",
          type: "videoAsset",
          showWhen: INVENTORY_PUZZLE_2_BUTTON_B,
          video: buttonVideo,
          closeOnEnd: true,
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "clickAnimation2",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 355,
        left: 1968,
        width: 56,
      },
    },
    {
      id: "roomGlow",
      type: "animation",
      trigger: {
        image: roomGlow,
        isSlow: true,
        position: {
          top: 0,
          left: 0,
          width: 3964,
        },
      },
    },
  ],
};

export default content;
