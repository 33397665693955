import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

function ModalBase({ children }) {
  const el = useRef(document.createElement("div")).current;

  useEffect(() => {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(el);

    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
}

function Modal({ children, showModal }) {
  // const modalTransition = useTransition(!!showModal, {
  //   config: showModal ? { ...config.stiff } : { duration: 150 },
  //   from: { opacity: 0, transform: `translate3d(0px, -10px, 0px)` },
  //   enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
  //   leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` }
  // });

  return showModal && <ModalBase>{children}</ModalBase>;
}

export default Modal;
