import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import soundAssetOn from "../../../assets/menu/btn_audio_on.png";
import controllerAsset from "../../../assets/menu/btn_controller.png";
import mapAsset from "../../../assets/menu/btn_map.png";
import soundAssetOff from "../../../assets/menu/btn_settings.png";
import backAsset from "../../../assets/menu/btn_terug.png";
import emptyAsset from "../../../assets/menu/empty.png";
import { INVENTORY_PUZZLE_1 } from "../../../content/inventory/inventoryItems";
import { setMuteStatus } from "../../../store/actions/app";
import { getMuteStatus, getUnlocked } from "../../../store/reducers";
import soundEffects from "../../../utilities/soundEffects";
import GameController from "../../interactives/gameController/gameController";
import MazeMap from "../../interactives/mazeMap/mazeMap";
import styles from "./menu.module.scss";
import MenuButton from "./menuButton";
import ProgressPanel from "./progressPanel";

function Menu({ handleClose }) {
  const unlockedArray = useSelector(getUnlocked);
  const [openController, setOpenController] = useState(false);
  const [openMazeMap, setOpenMazeMap] = useState(false);
  const mute = useSelector(getMuteStatus);
  const dispatch = useDispatch();

  function toggleBackGroundSound() {
    if (!mute) {
      soundEffects.stopBackgroundSound();
    } else {
      soundEffects.playBackgroundSound();
    }

    dispatch(setMuteStatus(!mute));
  }

  return (
    <div className={styles.menu}>
      <MenuButton
        handleClick={handleClose}
        position={[30, 20]}
        asset={!handleClose ? emptyAsset : backAsset}
        disabled={!handleClose}
      />
      <MenuButton
        handleClick={toggleBackGroundSound}
        position={[80, 90]}
        asset={mute ? soundAssetOff : soundAssetOn}
      />

      <ProgressPanel position={[85, 190]} />

      <MenuButton
        handleClick={() => setOpenController(true)}
        position={[80, 380]}
        asset={controllerAsset}
      />
      <MenuButton
        handleClick={() => setOpenMazeMap(true)}
        position={[40, 460]}
        disabled={!unlockedArray.includes(INVENTORY_PUZZLE_1)}
        asset={
          !unlockedArray.includes(INVENTORY_PUZZLE_1) ? emptyAsset : mapAsset
        }
      />
      <GameController
        open={openController}
        onClose={() => setOpenController(false)}
      />
      <MazeMap open={openMazeMap} onClose={() => setOpenMazeMap(false)} />
    </div>
  );
}

export default Menu;
