import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { getMuteStatus } from "../../../store/reducers";
import soundEffects from "../../../utilities/soundEffects";
import styles from "./cutScene.module.scss";

function CutScene({ content, callback, skipTo }) {
  const { backgroundImage, video } = content;
  const videoAssetRef = useRef(null);
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const muted = useSelector(getMuteStatus);
  useEffect(() => {
    soundEffects.stopBackgroundSound();
  }, []);

  useEffect(() => {
    if (videoEnded) {
      if (!callback) {
        navigate(content.ended.page);
      } else {
        callback();
      }
    }
  }, [videoEnded, content, navigate, callback, muted]);

  useEffect(() => {
    if (videoLoaded) {
      const promise = videoAssetRef.current.play();
      promise
        .then(() => {})
        .catch(() => {
          setShowControls(true);
        });
    }
  }, [videoLoaded, videoAssetRef, setShowControls]);

  useEffect(() => {
    const videoAsset = videoAssetRef.current;

    if (videoAsset) {
      videoAsset.addEventListener("ended", () => setVideoEnded(true));
      videoAsset.addEventListener("loadeddata", () => setVideoLoaded(true));
    }

    return () => {
      if (videoAsset)
        videoAsset.removeEventListener("ended", () => setVideoEnded(true));
      if (videoAsset)
        videoAsset.removeEventListener("loadeddata", () =>
          setVideoLoaded(true)
        );
    };
  }, [videoAssetRef, content]);

  function playIfNotPlaying() {
    videoAssetRef.current.play();
  }

  return (
    <div className={styles.roomContainer}>
      {!!backgroundImage && (
        <img src={backgroundImage} alt="" className={styles.imageAsset} />
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoAssetRef}
        src={video.source}
        className={styles.videoAsset}
        style={video.styles}
        muted={false}
        controls={showControls}
        autoPlay
        playsInline
        onClick={playIfNotPlaying}
      />
      {skipTo && <Link to={skipTo}>Skip intro</Link>}
    </div>
  );
}

export default CutScene;
