import ImageRoom from "../../components/rooms/imageRoom/imageRoom";
import Menu from "../../components/ui/menu/menu";
import content from "../../content/rooms/room3";
import styles from "./room.module.scss";

function Room3() {
  return (
    <>
      <ImageRoom content={content} />;
      <div className={styles.menuPlacer}>
        <Menu />
      </div>
    </>
  );
}

export default Room3;
