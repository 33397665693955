import { combineReducers } from "redux";

import app, * as fromApp from "./app";
import events, * as fromEvent from "./events";

const store = combineReducers({
  app,
  events,
});

export const getInventory = (state) => fromApp.getAppInventory(state.app);
export const getObjectValues = (state) => fromApp.getAppObjectValues(state.app);
export const getMuteStatus = (state) => fromApp.getAppMuteStatus(state.app);
export const getRoomTime = (state) => fromApp.getAppRoomTime(state.app);
export const getStartTime = (state) => fromApp.getAppStartTime(state.app);
export const getEndTime = (state) => fromApp.getAppEndTime(state.app);
export const getUnlocked = (state) => fromApp.getAppUnlocked(state.app);
export const getDebugStatus = (state) => fromApp.getAppDebugStatus(state.app);
export const getPersonalGoal = (state) => fromApp.getAppPersonalGoal(state.app);
export const getProgress = (state) => fromApp.getAppProgress(state.app);
/* Hints */
export const getHints = (state) => fromApp.getAppHints(state.app);
export const getShowHint = (state) => fromApp.getAppShowHint(state.app);
export const getPuzzlesSolved = (state) =>
  fromApp.getAppPuzzlesSolved(state.app);

export const getPhoneEvents = (state) => fromEvent.getPhone(state.events);
export const getPhoneEventsSeen = (state) =>
  fromEvent.getPhoneSeen(state.events);
export const getInventoryAnimationEvent = (state) =>
  fromEvent.getInventoryAnimation(state.events);
export const getProgressAnimationEvent = (state) =>
  fromEvent.getProgressAnimation(state.events);
export const getBatteryReminderSeen = (state) =>
  fromEvent.getBatteryReminderSeen(state.events);
export default store;
