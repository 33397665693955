import video from "../../assets/cutscenes/gate_open_560_3_HB.mp4";

const content = {
  id: "cutscene4",
  type: "cutscene",
  video: {
    source: video,
    styles: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      objectFit: "cover",
      backgroundSize: "cover",
    },
  },
  ended: {
    type: "redirect",
    page: "/room4",
  },
};

export default content;
