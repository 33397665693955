import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setObjectValue, setPuzzleSolved } from "../../../store/actions/app";
import { getObjectValues } from "../../../store/reducers";
import NumberInput from "../../input/numberInput/numberInput";
import PaternInput from "../../input/paternInput/paternInput";
import TextInput from "../../input/textInput/textInput";
import AnimationAsset from "../animationAsset/animationAsset";
// eslint-disable-next-line import/no-cycle
import Interactives from "../interactives";
import Modal from "./modal";
import styles from "./modal.module.scss";

function DetailModal({ content, onCodeUnlocked }) {
  const SCREEN_WIDTH = 560;

  const modalRef = useRef();
  const dispatch = useDispatch();
  const objectValues = useSelector(getObjectValues);

  const { detail, trigger, type, types, id, animations, objects } = content;
  const [showModal, setShowModal] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const currentTypeIndex = useRef();

  useEffect(() => {
    setCurrentType(type);
  }, [type]);

  useEffect(() => {
    function handleResize(event) {
      if (modalRef.current) {
        const ratio = event.target.innerWidth / SCREEN_WIDTH;
        modalRef.current.style.transform = `scale(${ratio})`;
      }
    }

    if (modalRef.current) {
      window.addEventListener("resize", handleResize);
      const ratio = window.innerWidth / SCREEN_WIDTH;
      modalRef.current.style.transform = `scale(${ratio})`;
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showModal]);

  useEffect(() => {
    if (types) {
      const typeIndex = objectValues.find((item) => item.id === id);

      if (typeIndex && typeIndex.value) {
        setCurrentType(types[typeIndex.value]);
        currentTypeIndex.current = typeIndex.value;
      } else {
        setCurrentType(types[0]);
        currentTypeIndex.current = 0;
      }
    }
  }, [types, objectValues, id]);

  function onInputCodeUnlocked(success) {
    let number;
    switch (success.type) {
      case "next-type":
        number = currentTypeIndex.current + 1;
        dispatch(setObjectValue({ id, value: number }));
        dispatch(setPuzzleSolved(success.puzzle));

        setCurrentType(types[number]);
        break;
      default:
        onCodeUnlocked(success);
        break;
    }
  }
  return (
    <div className={styles.modalContainer}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.clickArea}
        style={trigger.position}
        onClick={() => setShowModal(true)}
      >
        <img
          src={trigger.image}
          alt=""
          style={trigger.size}
          className={styles.object}
        />
      </div>
      <Modal showModal={showModal}>
        <div className={styles.imageModal}>
          <div ref={modalRef} className={styles.content}>
            <img src={detail.image} alt="" style={detail.size} />
            {currentType === "codelock" && (
              <NumberInput
                content={content}
                close={() => setShowModal(false)}
                onCodeUnlocked={onInputCodeUnlocked}
              />
            )}
            {currentType === "textlock" && (
              <TextInput
                content={content}
                close={() => setShowModal(false)}
                onCodeUnlocked={onInputCodeUnlocked}
              />
            )}
            {currentType === "paternlock" && (
              <PaternInput
                content={content}
                close={() => setShowModal(false)}
                onCodeUnlocked={onInputCodeUnlocked}
              />
            )}
            {!!animations &&
              animations.map((animation, index) => (
                <AnimationAsset key={index} content={animation} />
              ))}
            {objects && (
              <Interactives objects={objects} onCodeUnlocked={onCodeUnlocked} />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DetailModal;
