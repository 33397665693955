import ArrowAsset from "../../../assets/ui/arrow-right.png";
import styles from "./submitButton.module.scss";

function SubmitButton({ callback, disabled }) {
  return (
    <button
      className={styles.submitButton}
      type="submit"
      disabled={disabled}
      onClick={callback}
    >
      <img alt="submit" src={ArrowAsset} />
    </button>
  );
}

export default SubmitButton;
