import { Colour } from "../lib/Colour";
import { Cell } from "./Cell";

export class Point extends Cell {
  constructor(index: number, colour: Colour) {
    super(index);

    super.setColour(colour);

    this.addClass("point");
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  setColour(colour) {
    throw new TypeError("Cannot change the colour of a Point");
  }
}

export default Point;
