import cn from "classnames";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import EmptyAsset from "../../../assets/controller/controller_leeg.png";
import FullAsset from "../../../assets/controller/controller_vol.png";
import ButtonAAsset from "../../../assets/controller/gate_cont_A.png";
import ButtonBAsset from "../../../assets/controller/gate_cont_B.png";
import ButtonXAsset from "../../../assets/controller/gate_cont_X.png";
import ButtonYAsset from "../../../assets/controller/gate_cont_Y.png";
import LineAsset from "../../../assets/controller/Lijn_X.png";
import lottieLine from "../../../assets/lottie/arrowrow.json";
import lottieAudio from "../../../assets/lottie/audiowave.json";
import lottieCircle from "../../../assets/lottie/radial.json";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_2_BUTTON_A,
  INVENTORY_PUZZLE_2_BUTTON_B,
  INVENTORY_PUZZLE_2_BUTTON_X,
  INVENTORY_PUZZLE_2_BUTTON_Y,
} from "../../../content/inventory/inventoryItems";
import { getUnlocked } from "../../../store/reducers";
import BackMenu from "../../ui/menu/backMenu";
import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./gameController.module.scss";

function GameController({ open, onClose }) {
  const unlockedArray = useSelector(getUnlocked);
  const controllerInInventory = unlockedArray.includes(INVENTORY_CONTROLLER);
  const buttonAInInventory = unlockedArray.includes(
    INVENTORY_PUZZLE_2_BUTTON_A
  );
  const buttonBInInventory = unlockedArray.includes(
    INVENTORY_PUZZLE_2_BUTTON_B
  );
  const buttonXInInventory = unlockedArray.includes(
    INVENTORY_PUZZLE_2_BUTTON_X
  );
  const buttonYInInventory = unlockedArray.includes(
    INVENTORY_PUZZLE_2_BUTTON_Y
  );
  const gameControllerDetailPageStyles = cn(styles.gameControllerDetailPage, {
    [styles.open]: open,
  });
  const location = useLocation();

  return (
    <div className={gameControllerDetailPageStyles}>
      <LottieAsset
        content={{
          animation: lottieCircle,
          position: {
            top: 20,
            left: 40,
            width: 66,
          },
        }}
      />
      <LottieAsset
        content={{
          animation: lottieLine,
          position: {
            top: 35,
            left: 310,
            width: 222,
          },
        }}
      />
      <LottieAsset
        content={{
          animation: lottieAudio,
          position: {
            top: 460,
            left: 170,
            width: 212,
          },
        }}
      />
      <img
        className={styles.controller}
        alt="controller afbeelding"
        src={controllerInInventory ? FullAsset : EmptyAsset}
      />
      {buttonAInInventory && (
        <img
          className={styles.buttonA}
          alt="button A afbeelding"
          src={ButtonAAsset}
        />
      )}
      {buttonBInInventory && (
        <img
          className={styles.buttonB}
          alt="button B afbeelding"
          src={ButtonBAsset}
        />
      )}
      {buttonXInInventory && (
        <img
          className={styles.buttonX}
          alt="button X afbeelding"
          src={ButtonXAsset}
        />
      )}
      {buttonYInInventory && (
        <img
          className={styles.buttonY}
          alt="button Y afbeelding"
          src={ButtonYAsset}
        />
      )}
      <img className={styles.line} alt="" src={LineAsset} />
      <div className={styles.callToAction}>
        {!controllerInInventory && (
          <p>je hebt de controller nodig om de gate te openen</p>
        )}
        {controllerInInventory &&
          !buttonAInInventory &&
          !buttonBInInventory && (
            <p>JE MOET OP ZOEK NAAR DE KNOPPEN OM TERUG TE KEREN</p>
          )}
        {controllerInInventory &&
          buttonAInInventory &&
          location.pathname === "/room2" && (
            <p>JE HEBT DE A-KNOP GEVONDEN PROBEER NU DE GATE</p>
          )}

        {controllerInInventory &&
          !buttonBInInventory &&
          location.pathname === "/room3" && <p>GA OP ZOEK NAAR DE B-KNOP</p>}
        {controllerInInventory &&
          buttonBInInventory &&
          location.pathname === "/room3" && (
            <p>JE HEBT DE B-KNOP GEVONDEN PROBEER NU DE GATE</p>
          )}
        {controllerInInventory &&
          !buttonXInInventory &&
          location.pathname === "/room4" && <p>GA OP ZOEK NAAR DE X-KNOP</p>}
        {controllerInInventory &&
          buttonXInInventory &&
          location.pathname === "/room4" && (
            <p>JE HEBT DE X-KNOP GEVONDEN PROBEER NU DE GATE</p>
          )}

        {controllerInInventory &&
          !buttonYInInventory &&
          location.pathname === "/room5" && <p>GA OP ZOEK NAAR DE Y-KNOP</p>}
        {controllerInInventory &&
          buttonYInInventory &&
          location.pathname === "/room5" && (
            <p>JE HEBT DE Y-KNOP GEVONDEN PROBEER NU DE GATE</p>
          )}
      </div>
      <div className={styles.gameControllerMenu}>
        <BackMenu handleClose={onClose} />
      </div>
    </div>
  );
}

export default GameController;
