import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import LottieLines from "../../../assets/lottie/lines.json";
import GreenLightAsset from "../../../assets/roomLights/green_light.png";
import ButtonLockedAsset from "../../../assets/roomLights/locked.png";
import ButtonOffAsset from "../../../assets/roomLights/off.png";
import BlueLightAsset from "../../../assets/roomLights/off_light.png";
import ButtonOnAsset from "../../../assets/roomLights/on.png";
import SwitchHandle from "../../../assets/roomLights/radialSwitch.png";
import RedLightAsset from "../../../assets/roomLights/red_light.png";
import SwitchBGAsset from "../../../assets/roomLights/switchBg.png";
import {
  INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
  INVENTORY_PUZZLE_4_ROOMS_GREEN_UNLOCKED,
  INVENTORY_PUZZLE_4_ROOMS_RED_ON,
  INVENTORY_PUZZLE_4_ROOMS_RED_UNLOCKED,
} from "../../../content/inventory/inventoryItems";
import {
  removeUnlocked,
  setObjectValue,
  setUnlocked,
} from "../../../store/actions/app";
import { getObjectValues, getUnlocked } from "../../../store/reducers";
// import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./roomLights.module.scss";
import RoomLightsForm from "./roomLightsForm";

const ON = "ON";
const LOCKED = "LOCKED";
const OFF = "OFF";

const RED = "RED";
const BLUE = "BLUE";
const GREEN = "GREEN";

const COLORS = [BLUE, RED, GREEN];

const puzzles = {
  [RED]: {
    solution: "brein",
    puzzleKey: INVENTORY_PUZZLE_4_ROOMS_RED_UNLOCKED,
  },
  [GREEN]: {
    solution: "blij",
    puzzleKey: INVENTORY_PUZZLE_4_ROOMS_GREEN_UNLOCKED,
  },
};

function RoomLightIndicator({ status, asset, onLockClick }) {
  return (
    <div className={styles.indicator}>
      <div className={styles.indicatorLight}>
        <img src={asset} alt="" />
      </div>
      {status === ON && (
        <img className={styles.indicatorButton} src={ButtonOnAsset} alt="" />
      )}
      {status === LOCKED && (
        <button type="button" onClick={onLockClick}>
          <img
            className={styles.indicatorButton}
            src={ButtonLockedAsset}
            alt=""
          />
        </button>
      )}
      {status === OFF && (
        <button type="button" onClick={onLockClick}>
          <img className={styles.indicatorButton} src={ButtonOffAsset} alt="" />
        </button>
      )}
    </div>
  );
}

function RoomLights() {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const objectValues = useSelector(getObjectValues);

  const unlockedArray = useSelector(getUnlocked);
  const timer = useRef();
  const redUnlocked = unlockedArray.includes(
    INVENTORY_PUZZLE_4_ROOMS_RED_UNLOCKED
  );
  const greenUnlocked = unlockedArray.includes(
    INVENTORY_PUZZLE_4_ROOMS_GREEN_UNLOCKED
  );

  useEffect(() => {
    const roomLightSetting = objectValues.find(
      (item) => item.id === "ROOM_LIGHTS"
    );
    if (roomLightSetting) {
      setCurrentColorIndex(roomLightSetting.value);
    }

    return () => {
      switch (currentColorIndex) {
        case 0: // blue
          dispatch(removeUnlocked(INVENTORY_PUZZLE_4_ROOMS_RED_ON));
          dispatch(removeUnlocked(INVENTORY_PUZZLE_4_ROOMS_GREEN_ON));
          break;
        case 1: // Red
          dispatch(setUnlocked(INVENTORY_PUZZLE_4_ROOMS_RED_ON));
          dispatch(removeUnlocked(INVENTORY_PUZZLE_4_ROOMS_GREEN_ON));
          break;
        case 3: // Green
        default:
          dispatch(removeUnlocked(INVENTORY_PUZZLE_4_ROOMS_RED_ON));
          dispatch(setUnlocked(INVENTORY_PUZZLE_4_ROOMS_GREEN_ON));
          break;
      }
    };
  }, [objectValues, currentColorIndex, dispatch]);

  useEffect(() => {
    if (error) {
      timer.current = setTimeout(() => {
        setError(false);
      }, 500);
    }
  }, [error]);

  // useEffect(() => {
  //   console.log(currentColorIndex);
  //
  // }, [currentColorIndex, dispatch]);

  function handleSwitchClick() {
    let newColorIndex = currentColorIndex + 1;

    if (!redUnlocked && !greenUnlocked) {
      setError(true);
      return;
      // Both are unlocked
    }
    if (redUnlocked && greenUnlocked) {
      if (newColorIndex === COLORS.length) {
        newColorIndex = 0;
      }
      // Only middle is unlocked
    } else if (redUnlocked && !greenUnlocked) {
      if (newColorIndex === COLORS.length - 1) {
        newColorIndex = 0;
      }
    } else if (newColorIndex === 1) {
      newColorIndex = 2;
    } else {
      newColorIndex = 0;
    }

    setCurrentColorIndex(newColorIndex);
    dispatch(setObjectValue({ id: "ROOM_LIGHTS", value: newColorIndex }));
  }

  const switchHandleStyles = cn(styles.switchHandle, {
    [styles.top]: COLORS[currentColorIndex] === RED,
    [styles.right]: COLORS[currentColorIndex] === GREEN,
    [styles.shake]: error,
  });
  const roomLightsCarouselStyles = cn(styles.roomLightsCarousel, {
    [styles.move]: showForm,
  });

  function getStatus(color) {
    if (COLORS[currentColorIndex] === color) {
      return ON;
    }

    if (color === RED && !redUnlocked) {
      return LOCKED;
    }

    if (color === GREEN && !greenUnlocked) {
      return LOCKED;
    }

    return OFF;
  }

  function handleButtonClick(room, status, buttonIndex) {
    if (status === ON) {
      return;
    }
    if (status === LOCKED) {
      setShowForm(room);
    }
    if (status === OFF) {
      dispatch(setObjectValue({ id: "ROOM_LIGHTS", value: buttonIndex }));
      setCurrentColorIndex(buttonIndex);
    }
  }

  return (
    <div className={styles.roomLights}>
      <div className={roomLightsCarouselStyles}>
        {/* REMOVED BECAUSE OF IOS PERFORMANCE ISSUES */}
        {/* <LottieAsset */}
        {/*  content={{ */}
        {/*    animation: LottieLines, */}
        {/*    position: { top: 0, left: 0, width: 560 }, */}
        {/*  }} */}
        {/* /> */}
        <div className={styles.roomLightsPanel}>
          <div className={styles.indicators}>
            <RoomLightIndicator
              type="blue"
              asset={BlueLightAsset}
              status={getStatus(BLUE)}
              onLockClick={() => handleButtonClick(BLUE, getStatus(BLUE), 0)}
            />
            <RoomLightIndicator
              type="red"
              asset={RedLightAsset}
              status={getStatus(RED)}
              onLockClick={() => handleButtonClick(RED, getStatus(RED), 1)}
            />
            <RoomLightIndicator
              type="green"
              asset={GreenLightAsset}
              status={getStatus(GREEN)}
              onLockClick={() => handleButtonClick(GREEN, getStatus(GREEN), 2)}
            />
          </div>
          <div className={styles.message}>
            {currentColorIndex === 0 && <p>BLAUWE LICHT AAN</p>}
            {currentColorIndex === 1 && (
              <p>
                ROZE LICHT AAN
                <br />
                NIEUWE HINT ZICHTBAAR
              </p>
            )}
            {currentColorIndex === 2 && (
              <p>
                GOENE LICHT AAN
                <br />
                NIEUWE HINT ZICHTBAAR
              </p>
            )}
          </div>
          <div className={styles.switch}>
            <img src={SwitchBGAsset} alt="" className={styles.switchBg} />
            <button
              type="button"
              className={switchHandleStyles}
              onClick={handleSwitchClick}
            >
              <img src={SwitchHandle} alt="" />
            </button>
          </div>
        </div>
        <div className={styles.roomLightsForm}>
          <RoomLightsForm
            type={showForm}
            solution={puzzles[showForm]?.solution}
            puzzleKey={puzzles[showForm]?.puzzleKey}
            onClose={() => setShowForm(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default RoomLights;
