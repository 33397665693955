import Lottie from "lottie-react";

import animation from "../../../assets/lottie/audiowave.json";
import styles from "./hintPopup.module.scss";

function HintPopup({ children, onClose }) {
  return (
    <div className={styles.popop}>
      <div className={styles.panel}>
        {children}
        <div className={styles.footer}>
          <Lottie
            animationData={JSON.parse(JSON.stringify(animation))}
            className={styles.animation}
            loop
          />
          <div className={styles.spacer} />
          <button type="submit" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default HintPopup;
