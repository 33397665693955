export const addClass = (element, ...classes) =>
  element.classList.add(...classes)

export const emit = (target, event) => target.dispatchEvent(event)

export const emitCustom = (target, eventName, ...params) =>
  emit(
    target,
    new CustomEvent(eventName, {
      detail: params
    })
  )

export const empty = element => {
  while (element.hasChildNodes()) {
    element.firstChild?.remove()
  }
}

export const hasClass = (element, className) =>
  element.classList.contains(className)

export const off = (target, event, listener, options = {}) =>
  target.removeEventListener(event, listener, options)

export const on = (target, event, listener, options = {}) =>
  target.addEventListener(event, listener, options)

export const onEach = (target, events, listener, options = {}) =>
  events.forEach(event => on(target, event, listener, options))

export const once = (target, event, listener, options = {}) =>
  on(target, event, listener, {
    ...(typeof options === "boolean" ? { capture: options } : options),
    once: true
  })

export const removeClass = (element, ...classes) =>
  element.classList.remove(...classes)

export const s = (html, ...childNodes) => {
  const container = document.createElement("div")

  container.innerHTML = html

  const element = container.firstElementChild

  childNodes.forEach(childNode => {
    if (childNode instanceof Element) {
      element.append(childNode.element())

      return
    }

    if (childNode instanceof Node) {
      element.append(childNode)

      return
    }

    element.append(t(childNode))
  })

  return element
}

export const t = content => document.createTextNode(content)

export const toggleClass = (element, ...classes) =>
  classes.forEach(className => element.classList.toggle(className))

export class Element {
  #element

  constructor(element) {
    this.#element = element
  }

  static fromString(html) {
    return new Element(s(html))
  }

  addClass(...classes) {
    addClass(this.element(), ...classes)
  }

  append(...nodes) {
    nodes.forEach(node => {
      if (node instanceof Element) {
        node = node.element()
      }

      this.element().append(node)
    })
  }

  element() {
    return this.#element
  }

  emit(event) {
    return emit(this.element(), event)
  }

  emitCustom(eventName, ...params) {
    return emitCustom(this.element(), eventName, ...params)
  }

  empty() {
    empty(this.element())
  }

  hasClass(className) {
    return hasClass(this.element(), className)
  }

  off(event, listener, options = {}) {
    off(this.element(), event, listener, options)
  }

  on(event, listener, options = {}) {
    on(this.element(), event, listener, options)
  }

  onEach(events, listener, options = {}) {
    onEach(this.element(), events, listener, options)
  }

  once(event, listener, options = {}) {
    once(this.element(), event, listener, options)
  }

  query(selector) {
    return this.element().querySelector(selector)
  }

  queryAll(selector) {
    return this.element().querySelectorAll(selector)
  }

  remove() {
    this.element().remove()
  }

  removeClass(...classes) {
    removeClass(this.element(), ...classes)
  }

  toggleClass(...classes) {
    toggleClass(this.element(), ...classes)
  }
}

export default Element
