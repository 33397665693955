export const INVENTORY_MAP = "INVENTORY_MAP";
export const INVENTORY_CONTROLLER = "INVENTORY_CONTROLLER";
export const INVENTORY_PUZZLE_1 = "INVENTORY_PUZZLE_1";

export const INVENTORY_PUZZLE_2 = "INVENTORY_PUZZLE_2";

export const INVENTORY_PUZZLE_2_PANEL_1 = "INVENTORY_PUZZLE_2_PANEL_1";
export const INVENTORY_PUZZLE_2_PANEL_2 = "INVENTORY_PUZZLE_2_PANEL_2";
export const INVENTORY_PUZZLE_2_PANEL_3 = "INVENTORY_PUZZLE_2_PANEL_3";
export const INVENTORY_PUZZLE_2_PANEL_4 = "INVENTORY_PUZZLE_2_PANEL_4";
export const INVENTORY_PUZZLE_2_PANEL_5 = "INVENTORY_PUZZLE_2_PANEL_5";
export const INVENTORY_PUZZLE_2_PANEL_6 = "INVENTORY_PUZZLE_2_PANEL_6";
export const INVENTORY_PUZZLE_2_PANEL_7 = "INVENTORY_PUZZLE_2_PANEL_7";
export const INVENTORY_PUZZLE_2_PANEL_8 = "INVENTORY_PUZZLE_2_PANEL_8";
export const INVENTORY_PUZZLE_2_PANEL_9 = "INVENTORY_PUZZLE_2_PANEL_9";
export const INVENTORY_PUZZLE_2_BUTTON_A = "INVENTORY_PUZZLE_2_BUTTON_A";
export const INVENTORY_PUZZLE_2_BUTTON_B = "INVENTORY_PUZZLE_2_BUTTON_B";
export const INVENTORY_PUZZLE_2_BUTTON_X = "INVENTORY_PUZZLE_2_BUTTON_X";
export const INVENTORY_PUZZLE_2_BUTTON_Y = "INVENTORY_PUZZLE_2_BUTTON_Y";

export const INVENTORY_PUZZLE_3_FLOW = "INVENTORY_PUZZLE_3_FLOW";
export const INVENTORY_PUZZLE_4_ROOMS_RED_UNLOCKED =
  "INVENTORY_PUZZLE_4_ROOMS_RED_UNLOCKED";
export const INVENTORY_PUZZLE_4_ROOMS_GREEN_UNLOCKED =
  "INVENTORY_PUZZLE_4_ROOMS_GREEN_UNLOCKED";
export const INVENTORY_PUZZLE_4_ROOMS_RED_ON =
  "INVENTORY_PUZZLE_4_ROOMS_RED_ON";
export const INVENTORY_PUZZLE_4_ROOMS_GREEN_ON =
  "INVENTORY_PUZZLE_4_ROOMS_GREEN_ON";
export const INVENTORY_EVENT_NEXT_STAGE = "next-stage";
export const INVENTORY_EVENT_REDIRECT = "redirect";

const content = [
  {
    id: INVENTORY_CONTROLLER,
    code: "CONTROLLER_1",
  },
  {
    id: INVENTORY_MAP,
    code: "MAP_1",
  },
  {
    id: INVENTORY_PUZZLE_1,
    code: "INVENTORY_PUZZLE_1",
  },
];

export default content;
