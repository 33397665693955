// import questionAsset from "../../../assets/menu/btn_hint.png";
import { useDispatch, useSelector } from "react-redux";

import soundAssetOn from "../../../assets/menu/btn_audio_on.png";
import soundAssetOff from "../../../assets/menu/btn_settings.png";
import backAsset from "../../../assets/menu/btn_terug.png";
import emptyAsset from "../../../assets/menu/empty.png";
import { setMuteStatus } from "../../../store/actions/app";
import { getMuteStatus } from "../../../store/reducers";
import soundEffects from "../../../utilities/soundEffects";
import styles from "./menu.module.scss";
import MenuButton from "./menuButton";
import ProgressPanel from "./progressPanel";

function BackMenu({ handleClose }) {
  const mute = useSelector(getMuteStatus);
  const dispatch = useDispatch();

  function toggleBackGroundSound() {
    if (!mute) {
      soundEffects.stopBackgroundSound();
    } else {
      soundEffects.playBackgroundSound();
    }

    dispatch(setMuteStatus(!mute));
  }

  return (
    <div className={styles.menu}>
      <MenuButton
        handleClick={handleClose}
        position={[30, 20]}
        asset={backAsset}
      />
      <MenuButton
        handleClick={toggleBackGroundSound}
        position={[80, 90]}
        asset={mute ? soundAssetOff : soundAssetOn}
      />

      <ProgressPanel position={[85, 190]} />
      <MenuButton
        handleClick={handleClose}
        position={[80, 380]}
        asset={emptyAsset}
        disabled
      />
      <MenuButton
        handleClick={handleClose}
        position={[40, 460]}
        asset={emptyAsset}
        disabled
      />
    </div>
  );
}

export default BackMenu;
