export const secondsToClockTime = (seconds) => {
  if (seconds < 0) return "00:00";
  return new Date(seconds * 1000).toISOString().substr(14, 5);
};

export const secondsToClockTimeOnlyMinutes = (seconds) => {
  if (seconds < 0) return "00";
  return new Date(seconds * 1000).toISOString().substr(14, 2);
};

export default secondsToClockTime;
