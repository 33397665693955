import React from "react";

import PaternInput from "../input/paternInput/paternInput";
// eslint-disable-next-line import/no-cycle
import Scene from "../scene/scene";
import AnimationAsset from "./animationAsset/animationAsset";
import ClockAsset from "./clockAsset/clockAsset";
import Gate from "./gate/gate";
import ImageAsset from "./imageAsset/imageAsset";
import InventoryItem from "./inventoryItem/inventoryItem";
import LottieAsset from "./lottieAsset/lottieAsset";
import ActivitiesMinigame from "./minigames/activitiesMinigame/activitiesMinigame";
import ChatGame from "./minigames/chatGame";
import FlowMinigame from "./minigames/flowMinigame";
import HintCarousel from "./minigames/hintCarousel";
import HintWindowCarousel from "./minigames/hintWindowCarousel";
import QuestionForm from "./minigames/questionForm/questionForm";
import SlidePuzzle from "./minigames/slidepuzzle";
import WindowMinigame from "./minigames/windowMinigame";
import WordPulseHint from "./minigames/wordPulseHint/wordPulseHint";
// eslint-disable-next-line import/no-cycle
import DetailModal from "./modals/detailModal";
import ProgressItem from "./progressItem/progressItem";
import RedirectAsset from "./redirectAsset/redirectAsset";
import RoomLights from "./roomLights/roomLights";
import VideoAsset from "./videoAsset/videoAsset";

function Interactives({ objects, onCodeUnlocked, goToNextRoom, onVideoEnded }) {
  return (
    <>
      {objects.map((object, index) => (
        <React.Fragment key={index}>
          {object.type === "scene" && (
            <Scene key={index} content={object} goToNextRoom={goToNextRoom} />
          )}
          {object.type === "image" && (
            <DetailModal key={index} content={object} />
          )}
          {object.type === "textlock" && (
            <DetailModal
              key={index}
              content={object}
              onCodeUnlocked={onCodeUnlocked}
            />
          )}
          {object.type === "codelock" && (
            <DetailModal
              key={index}
              content={object}
              onCodeUnlocked={onCodeUnlocked}
            />
          )}
          {object.type === "paternlock" && (
            <PaternInput
              key={index}
              content={object}
              onCodeUnlocked={onCodeUnlocked}
            />
          )}
          {object.type === "combined" && (
            <DetailModal
              key={index}
              content={object}
              onCodeUnlocked={onCodeUnlocked}
            />
          )}
          {object.type === "progress" && (
            <ProgressItem key={index} content={object} />
          )}
          {object.type === "inventory" && (
            <InventoryItem key={index} content={object} />
          )}
          {object.type === "animation" && (
            <AnimationAsset key={index} content={object} />
          )}
          {object.type === "redirect" && (
            <RedirectAsset key={index} content={object} />
          )}
          {object.type === "countdown" && (
            <ClockAsset key={index} content={object} />
          )}
          {object.type === "slidepuzzle" && (
            <SlidePuzzle key={index} content={object} />
          )}
          {object.type === "windowMinigame" && (
            <WindowMinigame
              key={index}
              content={object}
              onClose={onVideoEnded}
            />
          )}
          {object.type === "lottie" && (
            <LottieAsset key={index} content={object} />
          )}
          {object.type === "imageAsset" && (
            <ImageAsset key={index} content={object} />
          )}
          {object.type === "videoAsset" && (
            <VideoAsset
              key={index}
              content={object}
              onVideoEnded={onVideoEnded}
            />
          )}
          {object.type === "chatGame" && (
            <ChatGame key={index} content={object} />
          )}
          {object.type === "flowMinigame" && (
            <FlowMinigame key={index} content={object} />
          )}
          {object.type === "hintCarousel" && (
            <HintCarousel key={index} content={object} />
          )}
          {object.type === "hintWindowCarousel" && (
            <HintWindowCarousel key={index} content={object} />
          )}
          {object.type === "roomLights" && (
            <RoomLights key={index} content={object} />
          )}
          {object.type === "activitiesMinigame" && (
            <ActivitiesMinigame key={index} content={object} />
          )}
          {object.type === "questionForm" && (
            <QuestionForm key={index} content={object} />
          )}
          {object.type === "wordPulseHint" && (
            <WordPulseHint key={index} content={object} />
          )}
          {object.type === "gateController" && (
            <Gate
              key={index}
              content={object}
              onCodeUnlocked={onCodeUnlocked}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default Interactives;
