import { Navigate } from "react-router";

import End from "../pages/end/end";
// eslint-disable-next-line import/no-cycle
import Page from "../pages/page";
import CutScene1 from "../pages/rooms/cutscenes/cutscene1";
import CutScene2 from "../pages/rooms/cutscenes/cutscene2";
import CutScene3 from "../pages/rooms/cutscenes/cutscene3";
import CutScene4 from "../pages/rooms/cutscenes/cutscene4";
import CutScene5 from "../pages/rooms/cutscenes/cutscene5";
import CutSceneSuccess from "../pages/rooms/cutscenes/cutsceneSuccess";
import Room1 from "../pages/rooms/room1";
import Room2 from "../pages/rooms/room2";
import Room3 from "../pages/rooms/room3";
import Room4 from "../pages/rooms/room4";
import Room5 from "../pages/rooms/room5";
import Start from "../pages/start/start";

export const START_PATH = "start";
export const ROOM_CUTSCENE_1 = "cutscene1";
export const ROOM_1 = "room1";
export const ROOM_CUTSCENE_2 = "cutscene2";
export const ROOM_2 = "room2";
export const ROOM_CUTSCENE_3 = "cutscene3";
export const ROOM_3 = "room3";
export const ROOM_CUTSCENE_4 = "cutscene4";
export const ROOM_4 = "room4";
export const ROOM_CUTSCENE_5 = "cutscene5";
export const ROOM_5 = "room5";
export const ROOM_CUTSCENE_SUCCESS = "cutscene_success";
export const END_PATH = "end";
export const INTRO_PATH = "intro";
export const BLACK_PATH = "black";

const routes = [
  {
    path: "/",
    element: <Page />,
    children: [
      {
        path: "/",
        element: <Navigate to="/start" replace />,
        name: "Home",
        exact: true,
      },
      {
        path: START_PATH,
        name: "Start",
        element: <Start />,
      },
      {
        path: ROOM_CUTSCENE_1,
        name: "Cutscene 1",
        element: <CutScene1 />,
      },
      {
        path: ROOM_1,
        name: "Room 1",
        element: <Room1 />,
      },
      {
        path: ROOM_CUTSCENE_2,
        name: "Cutscene 2",
        element: <CutScene2 />,
      },
      {
        path: ROOM_2,
        name: "Room 2",
        element: <Room2 />,
      },
      {
        path: ROOM_CUTSCENE_3,
        name: "Cutscene 3",
        element: <CutScene3 />,
      },
      {
        path: ROOM_3,
        name: "Room 3",
        element: <Room3 />,
      },
      {
        path: ROOM_CUTSCENE_4,
        name: "Cutscene 4",
        element: <CutScene4 />,
      },
      {
        path: ROOM_4,
        name: "Room 4",
        element: <Room4 />,
      },
      {
        path: ROOM_CUTSCENE_5,
        name: "Cutscene 5",
        element: <CutScene5 />,
      },
      {
        path: ROOM_5,
        name: "Room 5",
        element: <Room5 />,
      },
      {
        path: ROOM_CUTSCENE_SUCCESS,
        name: "Cutscene Success",
        element: <CutSceneSuccess />,
      },
      {
        path: END_PATH,
        name: "End",
        element: <End />,
      },
    ],
  },
];

export default routes;
