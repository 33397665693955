import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getUnlocked } from "../../../store/reducers";
import soundEffects, {
  SOUND_EFFECT_WRONG,
} from "../../../utilities/soundEffects";
import styles from "./unlockedAsset.module.scss";

function UnlockedAsset({ content, handleComplete, clickHandler }) {
  const { position, hideWhen } = content;
  const unlockedArray = useSelector(getUnlocked);
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);

    const timeoutId = setTimeout(() => {
      setHide(true);
      if (handleComplete && typeof handleComplete === "function") {
        handleComplete();
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [handleComplete]);

  if (shouldBeHidden || !handleComplete) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.unlockAnimation}
      style={position}
      onClick={clickHandler}
    >
      {!hide && <p className={styles.unlockLabel}>Unlocked</p>}
    </div>
  );
}

export default UnlockedAsset;
