import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  INVENTORY_PUZZLE_2_BUTTON_A,
  INVENTORY_PUZZLE_2_BUTTON_B,
  INVENTORY_PUZZLE_2_BUTTON_X,
  INVENTORY_PUZZLE_2_BUTTON_Y,
} from "../../../content/inventory/inventoryItems";
// eslint-disable-next-line import/no-cycle
import routes from "../../../routes";
import {
  addItemToInventory,
  setDebugStatus,
  setUnlocked,
} from "../../../store/actions/app";
import { getDebugStatus } from "../../../store/reducers";
import styles from "./debugMenu.module.scss";

function DebugMenu() {
  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useDispatch();
  const debugMode = useSelector(getDebugStatus);

  useEffect(() => {
    if (debugMode) {
      document.getElementById("root").firstElementChild.classList.add("debug");
    } else {
      document
        .getElementById("root")
        .firstElementChild.classList.remove("debug");
    }
  }, [debugMode]);

  function earnButtonA(id) {
    dispatch(addItemToInventory(id));
    dispatch(setUnlocked(id));
  }

  return (
    <div className={styles.debugContainer}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.debugButton}
        onClick={() => setOpenMenu(!openMenu)}
      />
      {openMenu && (
        <div className={styles.debugPage}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div
            className={styles.debugToggle}
            onClick={() => dispatch(setDebugStatus(!debugMode))}
          >
            Debug: {debugMode ? "on" : "off"}
          </div>
          <ul>
            {routes[0].children.map((route, index) => (
              <li key={index}>
                <Link to={route.path}>{route.name}</Link>
              </li>
            ))}
          </ul>
          <ul>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={() => earnButtonA(INVENTORY_PUZZLE_2_BUTTON_A)}>
                Verdien button A
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={() => earnButtonA(INVENTORY_PUZZLE_2_BUTTON_B)}>
                Verdien button B
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={() => earnButtonA(INVENTORY_PUZZLE_2_BUTTON_X)}>
                Verdien button X
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={() => earnButtonA(INVENTORY_PUZZLE_2_BUTTON_Y)}>
                Verdien button Y
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DebugMenu;
