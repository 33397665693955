export const preloadImages = async (list) => {
  const promises = await list.map(
    (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject();
      })
  );
  return Promise.all(promises);
};
export default preloadImages;
