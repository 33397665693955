import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PuzzleBGAsset from "../../../assets/flow/dot_grid.png";
import PuzzleSolvedAsset from "../../../assets/flow/dot_grid_solved.png";
// import LottieUnlocked from "../../../assets/lottie/unlocked.json";
import { INVENTORY_PUZZLE_3_FLOW } from "../../../content/inventory/inventoryItems";
import { setPuzzleSolved, setUnlocked } from "../../../store/actions/app";
import { getUnlocked } from "../../../store/reducers";
import UnlockedAsset from "../../ui/unlockedAsset/unlockedAsset";
// import LottieAsset from "../lottieAsset/lottieAsset";
import { Cell } from "./flow/components/Cell";
import { Grid } from "./flow/components/Grid";
import { Point } from "./flow/components/Point";
import { TouchHighlight } from "./flow/components/TouchHighlight";
import { Colour } from "./flow/lib/Colour";
import { LevelProvider } from "./flow/lib/LevelProvider";
import styles from "./flowMinigame.module.scss";

const PATH_COUNT = 4;

function FlowMinigame() {
  const flowGameRef = useRef();
  const dispatch = useDispatch();
  const unlockedArray = useSelector(getUnlocked);
  const [success, setSuccess] = useState(false);
  const [solved, setSolved] = useState(false);

  useEffect(() => {
    function onPointerUp(pathsComplete) {
      if (pathsComplete === PATH_COUNT) {
        setSolved(true);
      }
    }

    const levelProvider = new LevelProvider();
    const [height, width, levelData] = levelProvider.generate();
    const touchHighlight = new TouchHighlight(height, width);
    const grid = new Grid(
      height,
      width,
      levelData.map((pointColour, index) =>
        pointColour === Colour.NONE
          ? new Cell(index)
          : new Point(index, pointColour)
      ),
      touchHighlight,
      onPointerUp
    );

    if (!flowGameRef.current?.hasChildNodes()) {
      flowGameRef.current.append(grid.element(), touchHighlight.element());
    }
  }, [dispatch]);

  useEffect(() => {
    const isSolved = unlockedArray.includes(INVENTORY_PUZZLE_3_FLOW);
    if (isSolved) {
      setSuccess(true);
    }
  }, [unlockedArray]);

  function handleSuccess() {
    setSuccess(true);
    dispatch(setUnlocked(INVENTORY_PUZZLE_3_FLOW));
    dispatch(setPuzzleSolved(INVENTORY_PUZZLE_3_FLOW));
  }

  return (
    <div className={styles.flowGame}>
      {!success && (
        <>
          <img src={PuzzleBGAsset} alt="" className={styles.flowGameBG} />
          <div ref={flowGameRef} className={styles.flowGameGrid} />
        </>
      )}
      {success && <img src={PuzzleSolvedAsset} alt="" />}
      {solved && !success && (
        <UnlockedAsset
          handleComplete={handleSuccess}
          content={{
            position: { top: 250, left: 0, width: 540 },
          }}
        />
      )}
    </div>
  );
}

export default FlowMinigame;
