import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorAsset from "../../../assets/activities/error.png";
import SmileyAsset from "../../../assets/activities/smiley.png";
import LottieArrows from "../../../assets/lottie/arrowrow.json";
import lottieEq from "../../../assets/lottie/equalizer.json";
import LottieLines from "../../../assets/lottie/lines.json";
import BgAsset from "../../../assets/room2/frame.png";
import SelectedButtonBG from "../../../assets/windowsMinigame/aan.png";
import AssetLeftArrow from "../../../assets/windowsMinigame/arrow_left.png";
import AssetRightArrow from "../../../assets/windowsMinigame/arrow_right.png";
import ButtonBG from "../../../assets/windowsMinigame/uit.png";
import {
  removeUnlocked,
  setObjectValue,
  setUnlocked,
} from "../../../store/actions/app";
import { getObjectValues } from "../../../store/reducers";
import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./windowMinigame.module.scss";

const initialQuestions = [
  {
    question: "Bijna .... van jouw leeftijdsgenoten blowt <b>niet</b>",
    answers: ["8 van de 10 ", "4 van de 10"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question: "Je bereikt je doelen sneller zonder te blowen",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question: "Door blowen verlies je sneller je aandacht",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question: "Door blowen onthoudt je dingen beter",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 1,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 1,
  },
  {
    question: "Door blowen heb je <b>minder</b> motivatie om iets te gaan doen",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 1,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question:
      "De meeste jongeren schatten het aantal leeftijdsgenoten die blowen te hoog ",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question: "Door <b>niet</b> te blowen voel je je ....",
    answers: ["Sloom", "Frisser"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 1,
  },
  {
    question: "Blowen is verslavend ",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 1,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
  {
    question: "Blowen heeft gevolgen voor je gezondheid ",
    answers: ["Waar", "Niet waar"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
    correctAnswerIndex: 0,
  },
];

function Question({ onSelectAnswer, selectedAnswerIndex, questionItem }) {
  return (
    <div className={styles.question}>
      <p dangerouslySetInnerHTML={{ __html: questionItem.question }} />
      <div className={styles.answers}>
        <button type="button" onClick={() => onSelectAnswer(0)}>
          <img
            className={styles.bgImage}
            src={selectedAnswerIndex === 0 ? SelectedButtonBG : ButtonBG}
            alt="antwoordknop waar"
          />
          <span>{questionItem.answers[0]}</span>
        </button>
        <button type="button" onClick={() => onSelectAnswer(1)}>
          <img
            className={styles.bgImage}
            src={selectedAnswerIndex === 1 ? SelectedButtonBG : ButtonBG}
            alt="antwoordknop niet waar"
          />
          <span>{questionItem.answers[1]}</span>
        </button>
      </div>
    </div>
  );
}

function WindowMinigame({ onClose }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState(initialQuestions);

  const dispatch = useDispatch();
  const objectValues = useSelector(getObjectValues);

  const SCREEN_WIDTH = 560;

  function onSelectAnswer(answer) {
    const tempAnswers = [...answers];
    tempAnswers.push({ answerIndex: currentQuestionIndex, answer });

    if (answer === questions[currentQuestionIndex].rightAnswerIndex) {
      dispatch(
        setUnlocked(`INVENTORY_PUZZLE_2_PANEL_${currentQuestionIndex + 1}`)
      );
    } else {
      dispatch(
        removeUnlocked(`INVENTORY_PUZZLE_2_PANEL_${currentQuestionIndex + 1}`)
      );
    }
    const questionsArray = [...questions];
    questionsArray[currentQuestionIndex].selectedAnswerIndex = answer;
    dispatch(setObjectValue({ id: "WINDOW_MINIGAME", value: questionsArray }));

    setAnswers(tempAnswers);
  }

  function previousQuestion() {
    const newIndex = currentQuestionIndex - 1;
    setCurrentQuestionIndex(newIndex);
  }

  function nextQuestion() {
    const newIndex = currentQuestionIndex + 1;
    setCurrentQuestionIndex(newIndex);
  }

  useEffect(() => {
    const userQuestions = objectValues.find(
      (item) => item.id === "WINDOW_MINIGAME"
    );

    if (userQuestions) {
      setQuestions(userQuestions.value);
    }
  }, [objectValues]);

  function closeScreen() {
    onClose();
  }
  const correctAnswer = questions.filter(
    (e) => e.correctAnswerIndex === e.selectedAnswerIndex
  ).length;

  return (
    <div className={styles.windowMinigameContainer}>
      <LottieAsset
        content={{
          animation: LottieLines,
          position: { top: 0, left: 0, width: 560 },
        }}
      />
      <LottieAsset
        content={{
          animation: LottieArrows,
          position: { top: 56, left: 56, width: 222 },
        }}
      />

      <div className={styles.questionCarousel}>
        <div
          className={styles.questionContainer}
          style={{
            transform: `translateX(-${SCREEN_WIDTH * currentQuestionIndex}px)`,
          }}
        >
          {questions.map((question, key) => (
            <Question
              key={key}
              questionItem={question}
              selectedAnswerIndex={question.selectedAnswerIndex}
              onSelectAnswer={onSelectAnswer}
            />
          ))}
          <div className={styles.resultScreen}>
            <img src={BgAsset} className={styles.background} alt="" />
            <div className={styles.result}>
              <img
                alt=""
                src={
                  correctAnswer === questions.length ? SmileyAsset : ErrorAsset
                }
              />
              <p>
                {correctAnswer} van de {questions.length} goed
              </p>
            </div>
            <LottieAsset
              content={{
                animation: lottieEq,
                position: {
                  top: 465,
                  left: 60,
                  width: 442,
                },
              }}
              loop
            />
          </div>
        </div>
      </div>
      <div className={styles.questionNavigation}>
        {currentQuestionIndex > 0 && (
          <button type="button" onClick={previousQuestion}>
            <img src={AssetLeftArrow} alt="" />
          </button>
        )}
        <span className={styles.filler} />
        {currentQuestionIndex < questions.length && (
          <button type="button" onClick={nextQuestion}>
            <img src={AssetRightArrow} alt="" />
          </button>
        )}
        {currentQuestionIndex === questions.length && (
          <button type="button" onClick={closeScreen}>
            <img src={AssetRightArrow} alt="" />
          </button>
        )}
      </div>
      {currentQuestionIndex < questions.length && (
        <div className={styles.questionCount}>
          <p>
            {currentQuestionIndex + 1}/{questions.length}
          </p>
        </div>
      )}
    </div>
  );
}

export default WindowMinigame;
