function getImageURLFromObject(property, content, array) {
  if (property === "image") {
    if (content[property] && !content[property].startsWith("data:")) {
      array.push(content[property]);
    }
  }
  if (
    (property === "trigger" || property === "detail") &&
    content[property].image
  ) {
    if (!content[property].image.startsWith("data:")) {
      array.push(content[property].image);
    }
  }
}

function getImagesFromContent(content, imageArray = []) {
  // eslint-disable-next-line no-restricted-syntax
  for (const property in content) {
    if (property) {
      getImageURLFromObject(property, content, imageArray);
      if (property === "objects") {
        content[property].map((objectItem) => {
          getImagesFromContent(objectItem, imageArray);
          return objectItem;
        });
      }
      if (property === "states") {
        content[property].map((objectItem) => {
          getImagesFromContent(objectItem, imageArray);
          return objectItem;
        });
      }
    }
  }
}

export const fetchImageURLListFromContent = (content) => {
  const list = [];

  getImagesFromContent(content, list);

  return list;
};

export default getImageURLFromObject;
