import { useDispatch } from "react-redux";

import { setProgress } from "../../../store/actions/app";
import { setProgressAnimationEvent } from "../../../store/actions/events";
import styles from "./progressItem.module.scss";

function ProgressItem({ content }) {
  const { progress, trigger } = content;
  const dispatch = useDispatch();

  function addProgress() {
    dispatch(setProgress(progress));
    dispatch(setProgressAnimationEvent("progress"));
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.progress}
      style={trigger.position}
      onClick={addProgress}
    >
      <img src={trigger.image} alt="" style={trigger.size} />
    </div>
  );
}

export default ProgressItem;
