import { useSelector } from "react-redux";

import { getUnlocked } from "../../../store/reducers";
import styles from "./animationAsset.module.scss";

function AnimationAsset({ content }) {
  const { trigger, hideWhen } = content;
  let imageAnimation;
  const unlockedArray = useSelector(getUnlocked);
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  if (trigger.video) {
    imageAnimation = styles.animation;
  } else if (trigger?.isSlow) {
    imageAnimation = styles.animateFlickerSlow;
  } else {
    imageAnimation = styles.animateFlicker;
  }

  return (
    <div className={imageAnimation} style={trigger.position}>
      {trigger.image && !shouldBeHidden && (
        <img src={trigger.image} alt="" style={trigger.size} />
      )}
      {trigger.video && !shouldBeHidden && (
        <video
          src={trigger.video}
          alt=""
          style={content.size}
          autoPlay
          muted
          loop
          playsInline
        />
      )}
    </div>
  );
}

export default AnimationAsset;
