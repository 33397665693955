import cn from "classnames";
import { useSelector } from "react-redux";

import { getUnlocked } from "../../../store/reducers";
import styles from "./imageAsset.module.scss";

function ImageAsset({ content }) {
  const {
    hideWhen,
    showWhen,
    position,
    image,
    size,
    staticPosition,
    pulsating,
  } = content;
  const unlockedArray = useSelector(getUnlocked);
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  const shouldBeShown = unlockedArray.includes(showWhen);

  const imageAssetStyles = cn(styles.imageAsset, {
    [styles.static]: staticPosition,
    [styles.pulsating]: pulsating,
  });
  if (shouldBeHidden || (showWhen && !shouldBeShown)) {
    return null;
  }

  return (
    <div className={imageAssetStyles} style={position}>
      <img src={image} alt="" style={size} />
    </div>
  );
}

export default ImageAsset;
